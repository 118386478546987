import React from 'react';
import SelectNew from '../Forms/Select';
import SelectValidatorCustom from '../../atoms/SelectValidator'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
// import Edit from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Check from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import styles from './nuevaObra-jss';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
import Book from '@material-ui/icons/Book';
import Visibility from '@material-ui/icons/Visibility';
import Language from '@material-ui/icons/Language';
import LoaderFull from '../Loader/LoaderFull';
import { S3Image } from 'aws-amplify-react';
import VideoPaper from './VideoPaper';
import { withRouter } from 'react-router-dom';
import ReactDragList from 'react-drag-list'
//import AddToPhotos from '@material-ui/icons/AddToPhotos';

import { Checkbox, Input, InputLabel } from '@material-ui/core';
import PapperBlock from './../PapperBlock/PapperBlock';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

// Expandible
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import ScriptDetail from './ScriptDetail';
//GEO 11/04/2019
//Se agrega tooltip en boton
import Tooltip from '@material-ui/core/Tooltip';

// Input Tags
import Chip from '@material-ui/core/Chip';
import keycode from 'keycode';
import ChipInput from 'material-ui-chip-input';

// Tabla Catalogo
import AddCatalogue from '../Catalogue/AddCatalogue';
import Header from '../Catalogue/Header';
import TableCatalogue from '../Catalogue/TableCatalogue';

import MaterialDropZone from '../Forms/MaterialDropZone';
import CharacterPaper from './CharacterPaper';

//Services
import { apiPOST, returnCurrentUserId, apiElasticSearch } from '../../services/service_base';
import { saveFile, saveMultipleFiles, deleteFileComplete, getFile } from '../../services/storage';
import { getFileTitle } from '../../helpers/general';
import materialTypes from '../../utils/materialTypes';
import schedule from '../../utils/schedule';

//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../Notification/CustomNotification';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

// JCMY 20/06/2019
import Downshift from 'downshift';
import { renderInput, getSuggestions, renderSuggestion } from '../../routes/Pages/Reportes/autocomplete';
//isanchez logline richtext
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateFromHTML } from 'draft-js-import-html';
// import { getSelectedBlock } from "draftjs-utils";
import EditorStyle from '../../styles/components/TextEditor.scss';
import '../../styles/components/vendors/react-draft-wysiwyg/react-draft-wysiwyg.css';
// import { Editor } from '@bit/primefaces.primereact.editor';
import { Editor } from 'react-draft-wysiwyg';
import StepVigencias from './StepVigencias';
import StepEquipoLiterario from './StepEquipoLiterario';
import StepMoreInfo from './StepMoreInfo';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { LanguageCxt } from '../../language/LanguageContext';
import ls from 'local-storage';
import moment from 'moment';
import { Edit } from '@material-ui/icons';
import { markAsExternalProspect, markAsLockEnable, markAsProspect, updateProjects, updateProspects } from '../../helpers/workMarkedHelper';
import { sendNotificationToSpecific } from '../../services/notifications';
import { editorStringify } from '../../utils/validation';
import { isObject } from 'lodash';


//This should come from database 
const categories = [
  { value: 3, data: "Telenovelas" },
  { value: 4, data: "Unitarios" },
  { value: 5, data: "Series" },
  { value: 6, data: "Bioserie" },
  { value: 7, data: "Otros" }];

//AHDA 26/03/19
const elasticEquivalents = [
  { elastic: "pais", state: "new_country_origin", array: "countries" },
  { elastic: "fuente_data", state: "new_source", array: "sources" },
  { elastic: "formato", state: "new_format", array: "formats" },
  { elastic: "ventana", state: "new_platform", array: "platforms" },
  { elastic: "audiencia", state: "new_audience", array: "audiences" },
  { elastic: "genero", state: "new_genre", array: "genres" },
  { elastic: "subgenero", state: "new_subgenre", array: "subgenres" },
  { elastic: "tematica", state: "new_theme", array: "themes" },
  { elastic: "motivacion", state: "new_motivation", array: "motivations" },
  { elastic: "temporalidad", state: "new_temporality", array: "temporalities" },
  { elastic: "ambiente", state: "new_environment", array: "environments" },
  { elastic: "horario", state: "new_schedule", array: "schedule" },

];

// const contentLogline = {
//   blocks: [{
//       key: '637grLogline',
//       text: '',
//       type: 'unstyled',
//       depth: 0,
//       inlineStyleRanges: [],
//       entityRanges: [],
//       data: {}
//   }],
//   entityMap: {}
// };

const contentSipnosisCorta = {
  blocks: [{
    key: '637grSipCor',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  }],
  entityMap: {}
};

const contentSipnosisLarga = {
  blocks: [{
    key: '637grSipLar',
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {}
  }],
  entityMap: {}
};

class NuevaObra extends React.Component {

  constructor(props) {
    super(props);

    // const contentBlock = convertFromRaw(contentLogline);
    const contentBlockSipCor = convertFromRaw(contentSipnosisCorta);
    const contentBlockSipLar = convertFromRaw(contentSipnosisLarga);

    // const editorStateLogline = EditorState.createWithContent(contentBlock);
    const editorStateSipCor = EditorState.createWithContent(contentBlockSipCor);
    const editorStateSipLar = EditorState.createWithContent(contentBlockSipLar);

    //AHDA 29/04/2019
    //Added isCompleteEdit and hadError
    this.state = {
      //Loading
      isLoading: false,
      dense: false,
      //Stepper
      activeStep: 0,
      altLabel: true,
      //completed: {},
      steps: [
        'Generales de la Obra',
        'Sinopsis',
        'Personajes',
        'Evaluación',
        'Material',
        'Vigencia',
        'Equipos Literarios',
        'Mas Información'
      ],
      //Expandables
      expanded: 'panel1',
      //Files
      filesDiagram: [],
      filesEvaluation: [],
      filesMaterial: [],
      //Forms
      openFormAuthor: false,
      openFormCharacters: false,
      openFormMaterial: false,
      openEdit: false,
      //Notifications 
      notfType: "error",
      notfMesg: "Algo salió mal, intenta de nuevo más tarde",
      openError: false,
      //Authors Table
      headers: ["Id", "Nombre", "Participación", "Opciones"],
      data: [],
      //New Authors
      rawData: [],
      //Form (required with initial value)
      //Required fields need to be controlled 
      new_ev_id: 0,
      new_ev_date: null,
      new_versions: [],
      new_equipos: [],
      new_vigencia: undefined,
      new_moreInfo: {},
      original_versions: [],
      new_producers: [],
      new_characters: [],
      new_type: undefined,
      new_fileNumber: '',
      new_title: '',
      new_title_originalVersion: '',
      new_country_origin: undefined,
      new_version_number: '00',
      new_produced: undefined, // (initial value, so nothing is selected)
      new_empresa_proveedor: undefined,
      new_clasification: undefined,
      new_production: undefined,
      new_source: undefined,
      new_chap_duration: undefined,
      new_format: undefined,
      new_genre: undefined,
      new_aired_duration: undefined,
      otras_versiones: undefined,
      new_chapter_number: undefined,
      new_productionYear: '',
      new_airedChapters: '',
      new_logline: '',
      new_shortSynopsis: '',
      new_long_synopsis: '',
      new_author_name: undefined,
      new_theme: undefined,
      new_english_title: undefined,
      new_title_other: undefined,
      new_schedule: undefined,
      new_environment: undefined,
      new_suggested_schedules: [],
      new_motivation: undefined,
      new_audience: undefined,
      new_subgenre: undefined,
      new_temporality: undefined,
      new_platform: undefined,
      new_tags: [],
      new_author_rol: undefined,
      new_CharacterDiagram: undefined,
      new_EvaluationFile: undefined,
      //Material
      new_mScripts: [],
      new_mImages: [],
      new_mVideos: [],
      new_mOthers: [],
      new_material_type: "",
      new_material_idioma: "",
      new_materialMissing: "",
      new_materialDescription: "",
      new_materialAmount: "",
      new_materialVideoConfig: {},
      //Catalogues
      catalogs: ls.get("catalogue_list"),
      titles: [],
      producers: [],
      companies: [],
      sources: [],
      countries: [],
      durations: [],
      authors: [],
      formats: [],
      platforms: [],
      audiences: [],
      genres: [],
      subgenres: [],
      themes: [],
      motivations: [],
      temporalities: [],
      environments: [],
      languages: [],
      schedules: [],
      territories: [],
      roles_escritores: [],
      escritores: [],
      producerUsers: [],
      //Forms
      selected: null, //For editing in forms
      workId: 0,
      isEdit: false,
      isCompleteEdit: false,
      hadError: false,
      errornew_author_name: false,
      errornew_author_rol: false,
      acceptedFiles: [],
      body: {},
      bodyElastic: {},
      //Assigned producer
      asigned_producer: 0,
      //Audit
      auditSent: false, //AHDA 04/05/2019

      worksCatalog: [], //JCMY 20/06/2019
      lastPrimordialCode: [],//JCMY 24/06/2019
      disabledCode: false, //AHDA 26/06/2019
      // editorStateLogline,
      editorStateSipCor,
      editorStateSipLar,
      editorSipnosisCorta: '',
      editorSipnosisLarga: '',
      auxMaterialMissing: [],
      arrayOtherPrimigenia: [],
      arrayDelOtherPrimigenia: [],
      new_title_otherPrimigenia: '',
      uploadProgress: null,
      paramFiles: null,
      totalFiles: null,
      params: null,
      acceptedFilesEval: []
    };
  }

  // JCMY 20/06/2019
  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ new_title_originalVersion: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ new_title_originalVersion: changes.inputValue })
    }
  }

  handleStateChangePrimigenia = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ new_title_otherPrimigenia: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ new_title_otherPrimigenia: changes.inputValue })
    }
  }

  handleSaveToArray = changes => {
    let aux = this.state.arrayOtherPrimigenia;
    if (this.state.arrayOtherPrimigenia.findIndex(x => x === changes) === -1) {
      aux.push(changes)
    }
    this.setState({ arrayOtherPrimigenia: aux, new_title_otherPrimigenia: '' })
  }

  handleDeleteOtherPrimigenia = (item) => {
    let aux = this.state.arrayOtherPrimigenia;
    let found = aux.findIndex(x => x === item);
    if (found > -1) {
      aux.splice(found, 1);
    }
    this.setState({ arrayOtherPrimigenia: aux })
  }

  //Notifications
  handleCloseError = () => {
    this.setState({ openError: false });
  }

  //Stepper 
  handleNext = (skip) => {
    //For testing comment all (lines 206 - 704) and uncomment the following lines: 
    // let { activeStep } = this.state;
    // this.setState({ activeStep: activeStep + 1 });

    this.form.walk(this.form.childs).then(isValid => {
      if (isValid) {

        //Bool for editing 
        let { isEdit } = this.state;
        let { activeStep, bodyElastic, auditSent } = this.state;

        const elasticBody = Object.assign({}, bodyElastic);

        //AHDA 03/06/2019: 
        const bodyAudit = {
          id_usuario: returnCurrentUserId(),
          user_action: isEdit ? 17 : 10,
          work_id: 0,
          portal: 'acervo',
          screen: "confLiteraryWorks"
        }

        //TODO: change id in new work and add to audit, add variable for checking if audit has been sent in success
        switch (activeStep) {
          case 0:


            if (this.state.data.length <= 0) {
              //AHDA 20/05/2019: Added handleChangeExpand
              this.setState({
                openError: true,
                notfType: "info",
                notfMesg: "Debes agregar al menos un Autor/Adaptador",
                //expanded: "panel2", 
                hadError: true
              });

              this.handleChangeExpand("panel2")(null, true);
              return false;
            }
            else if (this.state.new_produced === 1 && this.state.new_producers <= 0) {
              //AHDA 20/05/2019: Added handleChangeExpand
              this.setState({
                openError: true,
                notfType: "info",
                notfMesg: "Debes agregar al menos un Productor",
                //expanded: "panel4", 
                hadError: true
              });

              this.handleChangeExpand("panel4")(null, true);
              return false;
            }
            // else if (this.state.new_type === 'adaptacion' && this.state.new_versions <= 0) {
            //   //AHDA 20/05/2019: Added handleChangeExpand
            //   this.setState({
            //     openError: true,
            //     notfType: "info",
            //     notfMesg: "Debes agregar al menos una versión",
            //     //expanded: "panel5", 
            //     hadError: true
            //   });
            //   this.handleChangeExpand("panel5")(null, true);
            //   return false;
            // }
            else if (this.state.new_type === 'adaptacion' && !this.state.new_title_originalVersion) {
              this.setState({
                openError: true,
                notfType: "info",
                notfMesg: "Debes agregar el título de la obra primigenia",

                hadError: true
              });
              this.handleChangeExpand("panel1")(null, true);
              return false;
            } else {

              //Get category
              const format = this.state.formats.find(f => f.value === this.state.new_format).data;
              let new_category = categories.find(c => c.data.indexOf(format) > -1); //AHDA 16/05/2019

              // JCMY 20/06/2019
              let id_obra_primigenia = 0;
              try {
                id_obra_primigenia = (this.state.worksCatalog.find(obj => obj.label === this.state.new_title_originalVersion)).id;
              } catch (error) {
                console.log(error)
              }

              let arrIdsPrimigenia = new Array();

              this.state.arrayOtherPrimigenia.map(
                item => {
                  arrIdsPrimigenia.push((this.state.worksCatalog.find(obj => obj.label === item)).id)
                }
              )

              const bodyWork = {
                empresa_proveedor: this.state.new_empresa_proveedor,
                titulo_original: this.state.new_title,
                titulo_inglés: this.state.new_english_title,
                titulo_otro_idioma: this.state.new_title_other,
                tipo_obra: this.state.new_type,
                obra_primigenia: this.state.new_title_originalVersion,
                numero_capitulos: this.state.new_chapter_number,
                duracion_min_capitulos: this.state.new_chap_duration,
                clasificacion: this.state.new_clasification,
                production: this.state.new_production,
                horario: this.state.new_schedule,
                codigo: this.getCode(), //JCMY 24/06/2019
                version: this.state.new_version_number,
                clave: this.getFileNumber(),
                id_usuario: returnCurrentUserId(),
                producida: this.state.new_produced,
                //Production
                anio_produccion: this.state.new_produced === 1 ? this.state.new_productionYear : null,
                ep_alAire: this.state.new_produced === 1 ? this.state.new_airedChapters : null,
                //End production
                valid: 1,
                typeProyect: 'terminada',
                aired_duration: this.state.new_aired_duration,
                //
                id_fuente: this.state.new_source,
                id_pais: this.state.new_country_origin,
                id_tematica: this.state.new_theme,
                id_formato: this.state.new_format,
                id_desarrollo: this.state.new_environment,
                id_suggested_schedules: this.state.new_suggested_schedules,
                id_motivacion_conflicto: this.state.new_motivation,
                id_audiencia: this.state.new_audience,
                id_genero: this.state.new_genre,
                id_subgenero: this.state.new_subgenre,
                id_temporalidad: this.state.new_temporality,
                id_ventana: this.state.new_platform,
                id_obras: this.state.workId,
                id_categorias: new_category ? new_category.value : 7,
                id_status: 4,
                id_obra_primigenia: id_obra_primigenia,
                id_otherPrimigenia: arrIdsPrimigenia,
                id_deleteOtherPrimigenia: this.state.arrayDelOtherPrimigenia
              }

              //AHDA 29/04/2019
              //Added skip validation
              if (skip && !this.state.hadError) {
                this.setState({
                  activeStep: activeStep + 1,
                  workId: this.state.workId,
                  body: bodyWork,
                  hadError: false
                });
                return false;
              }

              //Set loading 
              this.setState({ isLoading: true });

              if (isEdit) {
                bodyWork.logline = this.state.new_logline;
                bodyWork.sinopsis_corta = editorStringify(this.state.new_shortSynopsis);
                bodyWork.sinopsis_larga = editorStringify(this.state.new_long_synopsis);
              } else {
                elasticBody.id_status = 4;
                elasticBody.valid = 1;
                elasticBody.obra_vigencia = "";
              }

              apiPOST(!isEdit ? '/content/addWork' : '/content/updatework', bodyWork).then(
                response => {
                  //AHDA 24/04/2019
                  //Status validation added 
                  if (response.status === 200) {
                    //Validation for new work
                    if (response.data[0].exist) {
                      this.setState({
                        isLoading: false,
                        openError: true,
                        notfType: "error",
                        notfMesg: "El folio de la obra ya se encuentra en uso, favor de validar",
                        expanded: "panel1"
                      })
                      return false;
                    }

                    let workId = !isEdit ? response.data[0].idObra : this.state.workId;

                    //AHDA 04/06/2019
                    bodyAudit.work_id = workId;
                    if (!auditSent) {
                      apiPOST('/audit/add', bodyAudit).then(r => {
                        this.setState({ auditSent: true });
                      }).catch(err => {
                        console.log("Error in audit: ", err);
                      })
                    }

                    //For elastic search
                    //TODO: Change for sending only new data
                    elasticBody.id_obras = workId;
                    elasticBody.categoria = categories.find(c => c.value === bodyWork.id_categorias).data;
                    elasticBody.tags = this.state.new_tags;
                    elasticBody.folio = this.getFileNumber();

                    //it should only be sent if changed 
                    elasticBody.titulo_original = this.state.new_title;
                    elasticBody.titulo_ingles = this.state.new_english_title;
                    elasticBody.titulo_otro_idioma = this.state.new_title_other;
                    elasticBody.titulo_obra_primigenia = this.state.new_title_originalVersion;
                    elasticBody.clasificacion = this.state.new_clasification;
                    elasticBody.produccion = this.state.new_production;
                    elasticBody.tipo_obra = this.state.new_type;
                    elasticBody.producida = this.state.new_produced === 1 ? "Sí" : "No";
                    elasticBody.anio_produccion = this.state.new_produced === 1 ? this.state.new_productionYear : 0;
                    elasticBody.fuente = this.state.new_source;
                    elasticBody.horario_sugerido = this.state.schedules.filter(e => this.state.new_suggested_schedules.includes(e.value)).map((e) => e.data)


                    let errorAuthor = false, errorVersion = false, errorProducer = false;
                    this.setState({ workId: workId, body: bodyWork });

                    //This can go aside addauthor and still finish before 
                    //Add tags
                    //AHDA 16/05/2019: Added valdation for refreshing
                    apiPOST('/content/deletelabel', { work_id: workId }, true).then(
                      response => {
                        if (this.state.new_tags.length > 0) {
                          //AHDA 16/05/2019: Added valdation for refreshing
                          apiPOST('/content/addlabel', { work_id: workId, label_value: this.state.new_tags }, true).then(response => {
                          }).catch(err => {
                            console.log(err);
                          })
                        }
                      }).catch(err => {
                        console.log(err);
                      });

                    apiPOST('/content/updateworkhorario', { id_work: this.state.workId, type: "remove" })
                      .then(() => {
                        apiPOST('/content/updateworkhorario', { id_work: this.state.workId, type: "add", horarios: this.state.new_suggested_schedules })
                          .then(() => {

                          }).catch(err => {
                            console.log(err);
                          });
                      }).catch(err => {
                        console.log(err);
                      });

                    let bodyAuthors = {
                      authors: [...this.state.rawData],
                      work_id: workId
                    }
                    //Set work id for all authors 
                    for (let x in bodyAuthors.authors) {
                      bodyAuthors.authors[x].id_obra = workId;
                    }

                    //Add authors or modify
                    //AHDA 16/05/2019: Added valdation for refreshing
                    apiPOST(!isEdit ? '/content/addauthor' : '/content/updateauthor', bodyAuthors, true).then(response => {
                      if (response.status !== 200) {
                        errorAuthor = true;
                      }
                    }).catch(
                      err => {
                        //TODO: handle error
                        console.log(err);
                        errorAuthor = true;
                      }
                    ).finally(
                      () => {

                        //Send Notification
                        if (!isEdit) {
                          let bodyNotification = {
                            notification_type: "newWorkRegister",
                            id_work: workId,
                            id_notification_type: 1,
                          }

                          let bodyEmail = {
                            type: "newWorkRegister",
                            work: {
                              titulo_original: bodyWork.titulo_original,
                              tipo_obra: bodyWork.tipo_obra === "primigenia" ? "Primigenia" : "Adaptación",
                              pais: this.state.countries.find(c => c.value == bodyWork.id_pais).data,
                              autores: this.state.data.map(a => a.name).join(", "),
                              capitulos: bodyWork.numero_capitulos,
                              duracion_cap: bodyWork.duracion_min_capitulos,
                              formato: this.state.formats.find(f => f.value == bodyWork.id_formato).data,
                              genero: this.state.genres.find(g => g.value == bodyWork.id_genero).data,
                            }
                          }
                          this.props.sendNotification(1, bodyEmail, bodyNotification, 1);
                        } else {
                          //Send notification. 
                          let bodyNotification = {
                            notification_type: "workUpdate",
                            id_work: workId,
                            id_notification_type: 8,
                          }

                          let bodyEmail = {
                            type: "workUpdate",
                            work: {
                              titulo_original: bodyWork.titulo_original
                            }
                          }

                          this.props.sendNotification(8, bodyEmail, bodyNotification, 1);
                        }
                        //Get authors
                        let elasticAuthors = [...this.state.data];
                        elasticAuthors = elasticAuthors.map(a => a.name.split(" ").filter(e => e != "").join(" "));
                        //elasticAuthors = elasticAuthors.join(", ");
                        elasticBody.autores = elasticAuthors;

                        //Work type validation 
                        if (bodyWork.producida === 0 && bodyWork.tipo_obra === 'primigenia') {
                          //Call to ElasticSearch
                          apiElasticSearch('/doc/upsert-doc', { id: workId, doc: elasticBody })
                            .then()
                            .catch(err => console.log(err));

                          this.setState({
                            activeStep: activeStep + 1,
                            isLoading: false,
                            openError: errorAuthor,
                            notfType: "error",
                            notfMesg: `No se pudo guardar: Autores, puedes intentar editarlos mas tarde`,
                            hadError: false
                          });
                        } else {

                          this.addAdaptations(workId, bodyWork).then(response => {
                            if (response.status !== 200) {
                              errorVersion = true;
                            }
                          }).catch(
                            err => {
                              console.log(err);
                              errorVersion = true;
                            }).finally(
                              () => {
                                if (bodyWork.producida === 0) {

                                  //AHDA 09/05/2019 
                                  //Call to ElasticSearch
                                  apiElasticSearch('/doc/upsert-doc', { id: this.state.workId, doc: elasticBody })
                                    .then()
                                    .catch(err => console.log(err));

                                  if (errorVersion || errorAuthor) {
                                    this.setState({
                                      activeStep: activeStep + 1,
                                      isLoading: false,
                                      hadError: false,
                                      //openError: true,
                                      notfType: "error",
                                      notfMesg: `No se pudo guardar:${errorAuthor ? " Autores" : ""} 
                                  ${errorVersion ? " Adaptaciones" : ""}, puedes intentar editarlos mas tarde`
                                    });
                                  } else {
                                    this.setState({ activeStep: activeStep + 1, isLoading: false, hadError: false });
                                  }
                                } else {
                                  this.addProducers(workId).then(response => {
                                    if (response.status !== 200) {
                                      errorProducer = true;
                                    }
                                  }).catch(
                                    err => {
                                      console.log(err);
                                      errorProducer = true;
                                    }
                                  ).finally(
                                    () => {

                                      //TODO: get body and producers
                                      let elasticProducers = [];
                                      this.state.new_producers.map(a => {
                                        let auxItem = this.state.producers.find(p => p.value === a);
                                        elasticProducers.push(auxItem.data);
                                      });

                                      elasticProducers = elasticProducers.join(", ");
                                      elasticBody.productores = elasticProducers;

                                      //Call to ElasticSearch
                                      apiElasticSearch('/doc/upsert-doc', { id: this.state.workId, doc: elasticBody })
                                        .catch(err => console.log(err))
                                        .finally(() => {
                                          this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false });
                                        });

                                      if (errorVersion || errorAuthor || errorProducer) {
                                        this.setState({
                                          activeStep: activeStep + 1,
                                          hadError: false,
                                          isLoading: false,
                                          openError: true,
                                          notfType: "error",
                                          notfMesg: `No se pudo guardar:${errorAuthor ? " Autores" : ""} 
                                      ${errorVersion ? " Adaptaciones" : ""} ${errorProducer ? " Productores" : ""}, puedes intentar editarlos mas tarde`
                                        });
                                      }
                                    })
                                }
                              })
                        }
                      })
                  }
                  else {
                    //AHDA 09/05/2019
                    this.props.openFatalError();
                  }
                }
              ).catch(
                err => {
                  //TODO: handle error
                  console.log(err);
                  this.props.openFatalError();
                }
              )
            }
            break;
          case 1:

            //AHDA 29/04/2019
            //Added skip validation
            if (skip && !this.state.hadError) {
              this.setState({
                activeStep: activeStep + 1,
                hadError: false
              });
              return false;
            }

            // let convertTo = this.state.editorStateLogline.getCurrentContent();
            // const converted = convertToRaw(convertTo); 
            // converted.blocks = converted.blocks.map(b => {
            //   let auxObject = Object.assign({}, b); 
            //   auxObject.text = auxObject.text.replace(/\r\n|\r|\n/, " ");   
            //   return auxObject; 
            // }); 

            // let convertToSipCort = this.state.editorStateSipCor.getCurrentContent();
            // const convertedSipCort = convertToRaw(convertToSipCort); 
            // convertedSipCort.blocks = convertedSipCort.blocks.map(b => {
            //   let auxObject = Object.assign({}, b); 
            //   auxObject.text = auxObject.text.replace(/\r\n|\r|\n/g, " ");   
            //   return auxObject; 
            // }); 

            // let convertToSipLar = this.state.editorStateSipLar.getCurrentContent();
            // const convertedSipLar = convertToRaw(convertToSipLar); 
            // convertedSipLar.blocks = convertedSipLar.blocks.map(b => {
            //   let auxObject = Object.assign({}, b); 
            //   auxObject.text = auxObject.text.replace(/\r\n|\r|\n/g, " ");   
            //   return auxObject; 
            // }); 

            // // console.log("editor log line", JSON.stringify(converted))
            // console.log("editor short", JSON.stringify(convertedSipCort))
            // console.log("editor large", JSON.stringify(convertedSipLar))
            const format = this.state.formats.find(f => f.value === this.state.new_format).data;
            let new_category = categories.find(c => c.data.indexOf(format) > -1); //AHDA 16/05/2019
            let id_obra_primigenia = 0;
            try {
              id_obra_primigenia = (this.state.worksCatalog.find(obj => obj.label === this.state.new_title_originalVersion)).id;
            } catch (error) {
              console.log(error)
            }

            let arrIdsPrimigenia = new Array();

            this.state.arrayOtherPrimigenia.map(
              item => {
                arrIdsPrimigenia.push((this.state.worksCatalog.find(obj => obj.label === item)).id)
              }
            )

            const body = {
              empresa_proveedor: this.state.new_empresa_proveedor,
              titulo_original: this.state.new_title,
              titulo_inglés: this.state.new_english_title,
              titulo_otro_idioma: this.state.new_title_other,
              tipo_obra: this.state.new_type,
              obra_primigenia: this.state.new_title_originalVersion,
              numero_capitulos: this.state.new_chapter_number,
              duracion_min_capitulos: this.state.new_chap_duration,
              clasificacion: this.state.new_clasification,
              production: this.state.new_production,
              horario: this.state.new_schedule,
              codigo: this.getCode(), //JCMY 24/06/2019
              version: this.state.new_version_number,
              clave: this.getFileNumber(),
              id_usuario: returnCurrentUserId(),
              producida: this.state.new_produced,
              //Production
              anio_produccion: this.state.new_produced === 1 ? this.state.new_productionYear : null,
              ep_alAire: this.state.new_produced === 1 ? this.state.new_airedChapters : null,
              //End production
              valid: 1,
              typeProyect: 'terminada',
              aired_duration: this.state.new_aired_duration,
              //
              id_fuente: this.state.new_source,
              id_pais: this.state.new_country_origin,
              id_tematica: this.state.new_theme,
              id_formato: this.state.new_format,
              id_desarrollo: this.state.new_environment,
              id_suggested_schedules: this.state.new_suggested_schedules,
              id_motivacion_conflicto: this.state.new_motivation,
              id_audiencia: this.state.new_audience,
              id_genero: this.state.new_genre,
              id_subgenero: this.state.new_subgenre,
              id_temporalidad: this.state.new_temporality,
              id_ventana: this.state.new_platform,
              id_obras: this.state.workId,
              id_categorias: new_category ? new_category.value : 7,
              id_status: 4,
              id_obra_primigenia: id_obra_primigenia,
              id_otherPrimigenia: arrIdsPrimigenia,
              id_deleteOtherPrimigenia: this.state.arrayDelOtherPrimigenia
            }

            body.logline = this.state.new_logline;
            body.sinopsis_corta = editorStringify(this.state.editorSipnosisCorta);
            body.sinopsis_larga = editorStringify(this.state.editorSipnosisLarga);
            body.id_obras = this.state.workId;
            this.setState({ isLoading: true, new_shortSynopsis: this.state.editorSipnosisCorta, new_long_synopsis: this.state.editorSipnosisLarga });

            const parts = this.getFileNumber().split("_")

            body.clave = parts[0] + "_" + parts[1] + "_" + parts[2] + "_" + parts[3] + "_" + this.state.workId;


            let error = false;

            apiPOST('/content/updatework', body).then(response => {
              if (response.status === 500) {
                error = true;
              }
            }).catch(err => {
              console.log(err);
              error = true;
            }).finally(
              () => {

                //AHDA 04/06/2019
                bodyAudit.work_id = this.state.workId;
                if (!auditSent) {
                  apiPOST('/audit/add', bodyAudit).then(r => {
                    this.setState({ auditSent: true });
                  }).catch(err => {
                    console.log("Error in audit: ", err);
                  })
                }

                //Call to ElasticSearch
                apiElasticSearch('/doc/upsert-doc', { id: this.state.workId, doc: { logline: this.state.new_logline } })
                  .then()
                  .catch(err => console.log(err));

                if (error) {
                  this.setState({
                    activeStep: activeStep + 1,
                    hadError: false,
                    isLoading: false,
                    openError: true,
                    notfType: "error",
                    notfMesg: `No se pudo guardar: Logline, Sinopsis Corta, Sinopsis Larga, puedes intentar editarlos mas tarde`
                  });
                } else {
                  this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false });
                }
              }
            )
            break;
          case 2:
            if (this.state.new_characters.length <= 0) {
              this.setState({
                openError: true,
                notfType: "info",
                notfMesg: "Debes agregar al menos un Personaje",
                expanded: "panel3_1",
                hadError: true
              })
              return false;
            }
            else {

              //AHDA 29/04/2019
              //Added skip validation
              if (skip && !this.state.hadError) {
                this.setState({
                  activeStep: activeStep + 1,
                  hadError: false
                });
                return false;
              }

              //AHDA 04/06/2019
              bodyAudit.work_id = this.state.workId;
              if (!auditSent) {
                apiPOST('/audit/add', bodyAudit).then(r => {
                  this.setState({ auditSent: true });
                }).catch(err => {
                  console.log("Error in audit: ", err);
                })
              }

              this.setState({ isLoading: true });
              let bodyCharacters = { characters: [...this.state.new_characters], work_id: this.state.workId }
              let error = false, errorDiagram = false;
              for (let x in bodyCharacters.characters) {
                bodyCharacters.characters[x].id_obra = this.state.workId;
              }

              apiPOST(!isEdit ? '/content/addpersonage' : '/content/updatepersonage', bodyCharacters).then(response => {
                if (response.status !== 200) {
                  error = true;
                }
              }).catch(err => {
                console.log(err);
                error = true;
              }).finally(
                () => {
                  if (this.state.filesDiagram.length > 0) {
                    //Oh my home_P_USA_10000.00_175
                    //${this.getFileNumber()}
                    saveFile(`obras/${this.getFileNumber()}/otros`, this.state.filesDiagram[0], 'public').then(
                      response => {
                        let auxFile = {
                          tipo_archivo: "diagrama relaciones",
                          ruta: response.key,
                          id_obra: this.state.workId,
                          id_idioma: 1
                        }

                        apiPOST('/content/addmultimedia', { material: [auxFile] }).then(response => {
                          if (response.status !== 200) {
                            /// Here and in catch it should remove the file
                            errorDiagram = true;
                            auxFile = undefined;
                          } else {
                            auxFile.id_material_multimedia = response.data.new_ids[0];
                            /**
                             * "id_material_multimedia": 310,
                                "tipo_archivo": "evaluacion",
                                "ruta": "obras/Oh my home_P_USA_10000.00_175/evaluacion/1551995652058_evaluacion.pdf",
                                "id_obra": 175,
                                "id_idioma": 1
                            */
                          }
                        }).catch(err => { errorDiagram = true; auxFile = undefined; }).finally(
                          () => {
                            if (error || errorDiagram) {
                              this.setState({
                                new_CharacterDiagram: auxFile,
                                activeStep: activeStep + 1,
                                hadError: false,
                                isLoading: false,
                                openError: true,
                                notfType: "error",
                                notfMesg: `No se pudo guardar: ${error ? "Personajes" : ""}, ${errorDiagram ? "Diagrama" : ""}, puedes intentar editarlos mas tarde`
                              });
                            } else {
                              this.setState({ new_CharacterDiagram: auxFile, hadError: false, activeStep: activeStep + 1, isLoading: false });
                            }
                          });
                      }).catch(
                        err => {
                          console.log(err);
                          errorDiagram = true;
                        });
                  } else {
                    if (error) {
                      this.setState({
                        activeStep: activeStep + 1,
                        hadError: false,
                        isLoading: false,
                        openError: true,
                        notfType: "error",
                        notfMesg: `No se pudo guardar: Personajes, puedes intentar editarlos mas tarde`
                      });
                    } else {
                      this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false });
                    }
                  }
                });
            }
            break;
          case 3:

            //AHDA 29/04/2019
            //Added skip validation
            if (skip) {
              this.setState({
                activeStep: activeStep + 1,
                hadError: false
              });
              return false;
            }

            let bodyEvaluation = {
              comentarios: this.state.new_ev_comments,
              rating: this.state.new_ev_rating,
              horario: this.state.new_ev_schedule,
              share: this.state.new_ev_share,
              id_work: this.state.workId,
              work_id: this.state.workId,
              idEvaluacionObras: this.state.new_ev_id,
              countryEvaluations: [],
              fecha_evaluacion: new Date(this.state.new_ev_date)
            }

            let errorEvaluation = false, errorFileEv = false;
            if (!bodyEvaluation.comentarios && !bodyEvaluation.rating && !bodyEvaluation.horario
              && !bodyEvaluation.share && this.state.filesEvaluation.length <= 0) {
              this.setState({ activeStep: activeStep + 1, hadError: false });
            } else {

              this.setState({ isLoading: true });

              //AHDA 04/06/2019
              bodyAudit.work_id = this.state.workId;
              if (!auditSent) {
                apiPOST('/audit/add', bodyAudit).then(r => {
                  this.setState({ auditSent: true });
                }).catch(err => {
                  console.log("Error in audit: ", err);
                })
              }

              if (this.state.filesEvaluation.length > 0) {
                saveFile(`obras/${this.getFileNumber()}/evaluacion`, this.state.filesEvaluation[0], 'public', this.state.filesEvaluation[0].version).then(
                  response => {
                    let auxFile = {
                      tipo_archivo: "evaluacion",
                      ruta: response.key,
                      id_obra: this.state.workId,
                      id_idioma: 1
                    }

                    apiPOST('/content/addmultimedia', { material: [auxFile] }).then(response => {
                      if (response.status !== 200) {
                        /// Here and in catch it should remove the file
                        errorFileEv = true;
                        auxFile = undefined;
                      } else {
                        auxFile.id_material_multimedia = response.data.new_ids[0];
                        bodyEvaluation.multimedia = response.data.new_ids[0];
                        apiPOST(this.state.new_ev_id <= 0 ? '/content/addevaluation' : '/content/updateevaluation', bodyEvaluation)
                          .then(response => {

                            //Just set if it is new evaluation
                            if (response.status === 200 && this.state.new_ev_id <= 0) {
                              const temp = this.state.new_EvaluationFile || [];
                              temp.push({ ...auxFile, ...bodyEvaluation })
                              this.setState({ new_EvaluationFile: temp, activeStep: activeStep + 1, hadError: false, isLoading: false, new_ev_id: response.data[0].last_id, filesEvaluation: [] });

                            }


                            if (response.status !== 200) {
                              errorEvaluation = true;
                            }
                          }).catch(err => {
                            console.log(err);
                            errorEvaluation = true;
                          }).finally(
                            () => {
                              //Send notification
                              let bodyNotification = {
                                notification_type: "evaluationAssessment",
                                id_work: this.state.workId,
                                id_notification_type: 9,
                              }
                              let bodyEmail = {
                                type: "evaluationAssessment",
                                work: {
                                  titulo_original: this.state.new_title
                                }
                              }
                              this.props.sendNotification(8, bodyEmail, bodyNotification, 1);
                            })
                      }
                    }).catch(err => { errorFileEv = true; auxFile = undefined; }).finally(
                      () => {
                        if (errorEvaluation || errorFileEv) {
                          this.setState({
                            new_EvaluationFile: auxFile,
                            activeStep: activeStep + 1,
                            hadError: false,
                            isLoading: false,
                            openError: true,
                            notfType: "error",
                            notfMesg: `No se pudo guardar: ${errorEvaluation ? "Evaluacion" : ""}, ${errorFileEv ? "Dictamen" : ""}, puedes intentar editarlos mas tarde`
                          });
                        }
                      });
                  }).catch(
                    err => {
                      console.log(err);
                      errorFileEv = true;
                      this.setState({
                        activeStep: activeStep + 1,
                        hadError: false,
                        isLoading: false,
                        openError: true,
                        notfType: "error",
                        notfMesg: `No se pudo guardar: ${errorEvaluation ? "Evaluacion" : ""}, ${errorFileEv ? "Dictamen" : ""}, puedes intentar editarlos mas tarde`
                      });
                    });
              } else {
                if (errorEvaluation) {
                  this.setState({
                    activeStep: activeStep + 1,
                    hadError: false,
                    isLoading: false,
                    openError: true,
                    notfType: "error",
                    notfMesg: `No se pudo guardar: Evaluacion, puedes intentar editarlos mas tarde`
                  });
                }
                else {
                  this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false });
                }
              }

            }
            break;
          case 4:

            //AHDA 29/04/2019
            //Added skip validation
            if (skip) {
              this.setState({
                activeStep: activeStep + 1,
                hadError: false
              });
              return false;
            }

            this.state.auxMaterialMissing.map(
              item => {

                let body = {
                  work_id: item.work_id,
                  language_id: item.language_id,
                  missing_chapters: item.missing_chapters,
                  total_chapters: item.total_chapters
                }

                apiPOST('/content/updatescripts', body).then(response => {
                }).catch(err => {
                  console.log(err);
                });

              }
            )

            //THIS STEP STAYS THE SAME FOR INSERT OR UPDATE
            //TODO: Handle optional steps 
            let s3Files = [];
            let errorScripts = false, errorFiles = false;

            //get new others && scripts 
            let auxOthers = this.state.new_mOthers.map(o => o.files),
              auxScripts = this.state.new_mScripts.map(s => s.scripts);

            auxOthers = auxOthers.concat.apply([], auxOthers);
            auxScripts = auxScripts.concat.apply([], auxScripts);

            //Get only new files for s3 
            s3Files = s3Files.concat(
              this.state.new_mImages.filter(img => img.new),
              this.state.new_mVideos.filter(vid => vid.new),
              auxOthers.filter(other => other.new),
              auxScripts.filter(script => script.new)
            );

            // ------------------ ElasticSearch
            let files = [], filesToElastic = [];

            files = files.concat(
              this.state.new_mImages.filter(img => !img.new),
              this.state.new_mVideos.filter(vid => !vid.new));

            let bodyScript = {
              scripts: this.state.new_mScripts.filter(s => s.new === true).map(({ language_id, work_id, total_chapters, missing_chapters }) =>
                ({ language_id, work_id, total_chapters, missing_chapters }))
            }

            //Set loading
            this.setState({ isLoading: true, uploadProgress: 0.5 });
            //Insert scripts 
            apiPOST('/content/addscripts', bodyScript).then(response => {
              if (response.status !== 200) {
                errorScripts = true;
              }
            }).catch(
              err => errorScripts = true
            ).finally(() => {



              //AHDA 04/06/2019
              bodyAudit.work_id = this.state.workId;
              if (!auditSent) {
                apiPOST('/audit/add', bodyAudit).then(r => {
                  this.setState({ auditSent: true });
                }).catch(err => {
                  console.log("Error in audit: ", err);
                }).finally(() => {
                  let bodyNotification = {
                    notification_type: "workUpdate",
                    id_work: this.state.workId,
                    id_notification_type: 8,
                  }

                  let bodyEmail = {
                    type: "workUpdate",
                    work: {
                      titulo_original: this.state.new_title
                    }
                  }

                  this.props.sendNotification(8, bodyEmail, bodyNotification, 1);
                })
              }

              if (s3Files.length <= 0) {
                this.setState({
                  activeStep: activeStep + 1, hadError: false, openError: errorScripts, notfType: "error",
                  notfMesg: `No se pudo guardar: Libretos, puedes intentar editarlos mas tarde`, isLoading: false
                });
                return false;
              }
              //Assign folder
              for (let x in s3Files) {
                //Oh my home_P_USA_10000.00_175
                //${this.getFileNumber()}
                let folder = s3Files[x].tipo_archivo === "video" ? "videos" : s3Files[x].tipo_archivo === "libreto" ? "libretos" : "otros";
                s3Files[x].path = `obras/${this.getFileNumber()}/${folder}`;
                s3Files[x].id_idioma = Number.parseInt(s3Files[x].id_idioma);
              }

              let filesToRDS = [];


              saveMultipleFiles(s3Files, 'public', this.updateProgress).then(response => {

                if (this.state.asigned_producer > 0) {

                  let bodyNotification = {
                    notification_type: "aditionalMaterialRegister",
                    id_work: this.state.workId,
                    id_notification_type: 14,
                  }

                  let bodyEmail = {
                    type: "aditionalMaterialRegister",
                    work: {
                      titulo_original: this.state.new_title
                    }
                  }

                  this.props.sendNotification(14, bodyEmail, bodyNotification, 2, this.state.asigned_producer);
                }

                filesToRDS = filesToRDS.concat(response.keys);
                //Get if errors
                if (response.errors.length > 0)
                  errorFiles = true;

                filesToRDS = filesToRDS.map(({ id_idioma, id_obra, ruta, tipo_archivo, description, amount, video_config }) => {
                  if (tipo_archivo === "video") {
                    const bodyAudit = {
                      id_usuario: returnCurrentUserId(),
                      user_action: 25,
                      work_id: this.state.workId,
                      portal: 'acervo',
                      screen: "confLiteraryWorks"
                    }
                    apiPOST('/audit/add', bodyAudit)
                  }
                  return { id_idioma, id_obra, ruta, tipo_archivo, description, amount, video_config: JSON.stringify(video_config) }
                }
                );
                apiPOST('/content/addmultimedia', { material: filesToRDS }).then(response => {
                  if (response.status !== 200) {
                    //TODO: delete files from s3
                    errorFiles = true;
                  }
                }).catch(err => {
                  //TODO: delete files from s3 
                  errorFiles = true;
                }).finally(() => {

                  //AHDA 16/05/2019
                  for (let x in filesToRDS) {
                    if (filesToRDS[x].tipo_archivo === "img" || filesToRDS[x].tipo_archivo === "video") {
                      files.push(filesToRDS[x]);
                    }
                  }

                  for (let i = 0; i < files.length; i++) {
                    let auxItem = { type: files[i].tipo_archivo, url: files[i].ruta }
                    filesToElastic.push(auxItem);
                  }

                  //AHDA 09/05/2019
                  //Added thumbnail to elastic
                  let thumbnail = "";
                  thumbnail = filesToElastic.filter(f => f.type === "img").length > 0 ? filesToElastic.filter(f => f.type === "img")[0].url : "";
                  //Call to ElasticSearch
                  apiElasticSearch('/doc/upsert-doc', { id: this.state.workId, doc: { multimedia: filesToElastic, thumbnail: thumbnail } })
                    .then()
                    .catch(err => console.log(err));

                  let errorGeneralFiles = false;

                  if (errorScripts || errorFiles) {
                    errorGeneralFiles = true;
                  }

                  this.setState({
                    uploadProgress: null,
                    activeStep: activeStep + 1,
                    hadError: false,
                    isLoading: false,
                    openError: errorGeneralFiles,
                    notfType: "error",
                    notfMesg: `Algunos archivos no se pudieron guardar, intenta agregarlos mas tarde`
                  });
                });
              });
            });
            break;
          case 5:
            if (skip) {
              this.setState({
                activeStep: activeStep + 1,
                hadError: false
              });
              return false;
            }
            if (!this.state.new_vigencia) {
              this.setState({
                activeStep: activeStep + 1,
                hadError: false
              });
              return false;
            }
            this.setState({ isLoading: true });
            apiPOST('/content/updatevigencia', this.state.new_vigencia)
              .then(() => {
                //Call to ElasticSearch
                const elasticBody = Object.assign({}, this.state.bodyElastic);
                elasticBody.id_obras = this.state.workId;
                elasticBody.obra_vigencia = this.state.new_vigencia ? this.state.new_vigencia.type : null
                elasticBody.validity_options = JSON.stringify(this.state.new_vigencia.options)
                apiElasticSearch('/doc/upsert-doc', { id: this.state.workId, doc: elasticBody })
                  .then(() => {
                    const bodyAudit = {
                      id_usuario: returnCurrentUserId(),
                      user_action: 26,
                      work_id: this.state.workId,
                      portal: 'acervo',
                      screen: "confLiteraryWorks"
                    }
                    apiPOST('/audit/add', bodyAudit)
                    this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false });
                  })
                  .catch(() => this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false }))
              }).catch(err => {
                console.log(err);
              });
            break;
          case 6:
            if (skip) {
              this.setState({
                activeStep: activeStep + 1,
                hadError: false
              });
              return false;
            }
            let valid = true;
            const temp = this.state.new_equipos;
            temp.forEach((item) => {
              item.version_equipo_err = false;
              item.value_err = false;
              item.description_err = false;
              if (item.version_equipo === "") {
                valid = false;
                item.version_equipo_err = true;
              }
              if (item.value === "") {
                valid = false;
                item.value_err = true;
              }
              if (item.version_equipo === -1 && (item.description === undefined || item.description === "")) {
                valid = false;
                item.description_err = true;
              }
            });

            if (valid) {
              this.setState({ isLoading: true });
              apiPOST('/content/updateequipo', { id_work: this.state.workId, action: "remove" })
                .then(() => {
                  apiPOST('/content/updateequipo', { id_work: this.state.workId, action: "add", equipos: this.state.new_equipos })
                    .then(() => {
                      this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false });
                    }).catch(err => {
                      console.log(err);
                    });
                }).catch(err => {
                  console.log(err);
                });
            } else {
              this.setState({ new_equipos: temp })
            }
            break;

          case 7:

            this.setState({
              activeStep: activeStep + 1,
              hadError: false
            });
            return false;
            break;
          default:
            this.setState({ activeStep: activeStep + 1, hadError: false, isLoading: false });
            break;
        }
      } else {

        //AHDA 22/04/2019
        //Added open expand functionality
        setTimeout(() => {
          let auxErrors = [];

          this.form.childs.map(f => {
            if (!f.state.isValid) {
              auxErrors.push(f);
            }
          });

          let auxFirstError = auxErrors[0].props.name;
          switch (auxFirstError) {
            //TODO: Add panel
            case "new_aired_duration":
            case "new_airedChapters":
            case "new_productionYear":
              //isanchez 13.05.2019 add handleChangeExpand to load data
              this.handleChangeExpand("panel4")(null, true); //AHDA 20/05/2019: Changed function call
              this.setState({ hadError: true });
              break;
            case "new_genre":
            case "new_format":
              //isanchez 13.05.2019 add handleChangeExpand to load data
              this.handleChangeExpand("panel3")(null, true); //AHDA 20/05/2019: Changed function call
              this.setState({ hadError: true });
              break;
            //AHDA 29/04/2019
            //Added cases for logline, short synopsis and long synopsis
            case "new_logline":
              this.setState({ expanded: "panel2_1", hadError: true });
              break;
            case "new_shortSynopsis":
              this.setState({ expanded: "panel2_2", hadError: true });
              break;
            case "new_long_synopsis":
              this.setState({ expanded: "panel2_3", hadError: true });
              break;
            default:
              this.setState({ expanded: "panel1", hadError: true });
              break;
          }
        }, 20); //AHDA 30/05/2019 
      }
    }).catch(err => {
      console.log("Error: ", err);
    });

  };

  markAsProspect = (checked) => {
    let auxUser = returnCurrentUserId()
    const { new_moreInfo, workId } = this.state;
    const auxUsers = new_moreInfo.assignatorProspect || [];
    
    const request = {
      users: [
        ...(auxUsers.length > 0 ? 
          [{ assignedUser: auxUsers[0].assignedUser, isAdded: checked }] : []),
        { assignedUser: auxUser, isAdded: checked }
      ],
      idObra: workId,
      allocatorId: auxUser,
      screen: "confLiteraryWorks"
    };

    apiPOST('/obras/markAsProspect', request).then(
      response => {
        if (response.status === 200) {
          updateProspects(this.state.workId, checked);
        }
      }
    ).catch(
      () => {

      }
    );
  }

  assignProspects = (data) => {
    if (!data && data.length == 0) {
      return false;
    }
    let valid = true;
    data.forEach((item) => {
      item.assignedUser_err = false;
      item.assignedDate_err = false;
      if (item.assignedUser === "") {
        valid = false;
        item.assignedUser_err = true;
      }
      if (item.assignedDate === "") {
        valid = false;
        item.assignedDate_err = true;
      }
    });
    if (valid) {
      this.setState({ isLoading: true });
      let auxUser = returnCurrentUserId()
      const request = {
        users: data,
        idObra: this.state.workId,
        allocatorId: auxUser,
        screen: "admin"
      }
      
      apiPOST('/obras/markAsProspect', request).then(
        () => {
          this.setState({
            hadError: false,
            isLoading: false,
            openError: true,
            notfType: "success",
            notfMesg: this.context.lang.adminWork.prospecteUpdated
          });

          data.filter((e) => e.isAdded === true).map((e) => {
            let bodyEmail = {
              type: "workProspectUser",
              work: {
                titulo_original: this.state.new_title
              }
            }
            let bodyNotification = {
              notification_type: "workProspectUser",
              id_work: this.state.workId,
              id_notification_type: 24
            }
            sendNotificationToSpecific(24, bodyEmail, bodyNotification, e.assignedUser);
          })

        }
      ).catch(
        () => {
          this.setState({
            hadError: false,
            isLoading: false,
            openError: true,
            notfType: "error",
            notfMesg: this.context.lang.common.serviceError
          });
        }
      );
      if(this.state.notfType === "success") {
        return true
      } else {
        return false
      }
    } else {
      const temp = this.state.new_moreInfo;
      temp.assignProspects = data;
      this.setState({
        new_moreInfo: temp,
        hadError: false,
        isLoading: false,
        openError: true,
        notfType: "warning",
        notfMesg: this.context.lang.adminWork.requiredFields
      });
      return false
    }

  }

  assignProject = (status) => {
    if (!this.state.new_moreInfo.assignedTo) {
      const temp = this.state.new_moreInfo;
      temp.assignedTo_err = true;
      this.setState({
        new_moreInfo: temp,
        hadError: false,
        isLoading: false,
        openError: true,
        notfType: "warning",
        notfMesg: this.context.lang.adminWork.missingProducer
      });
      return false;
    }
    this.setState({ isLoading: true });

    const request = {
      users: [this.state.new_moreInfo.assignedTo],
      idObra: this.state.workId,
      status,
      assigner: returnCurrentUserId(),
      screen: "admin"
    }
    apiPOST('/obras/markAsProject', request)
      .then((res) => {
        const temp = this.state.new_moreInfo;
        temp.idmisProyectos = request.status === 0 ? null : res.data;
        const isExternal = returnCurrentUserId() === Number.parseInt(this.state.new_moreInfo.assignedTo) ? false : true;
        const isAdded = request.status === 1 ? true : false;
        updateProjects(this.state.workId, isAdded, isExternal);
        this.setState({
          activeStep: this.state.activeStep + 1,
          hadError: false,
          isLoading: false,
          openError: true,
          notfType: "success",
          notfMesg: this.context.lang.adminWork.projectUpdated
        });
        if (request.status === 1) {
          let bodyEmail = {
            type: "workAssigned",
            work: {
              titulo_original: this.state.new_title
            },
            user: {
              name: this.state.producerUsers.find(p => p.value === `${this.state.new_moreInfo.assignedTo}`).data
            }
          }
          let bodyNotification = {
            notification_type: "workAssigned",
            id_work: this.state.workId,
            id_notification_type: 13,
            specifiedProductor: this.state.new_moreInfo.assignedTo
          }
          sendNotificationToSpecific(13, bodyEmail, bodyNotification, this.state.new_moreInfo.assignedTo);

        }

        this.setState({ open: true });

      }).catch(
        (e) => {
          console.error("error", e)
          this.setState({
            activeStep: this.state.activeStep + 1,
            hadError: false,
            isLoading: false,
            openError: true,
            notfType: "error",
            notfMesg: this.context.lang.common.serviceError
          });
        }
      );
  }

  updateProgress = (progress) => {
    if (!this.state.uploadProgress || progress > this.state.uploadProgress)
      this.setState({ uploadProgress: progress.toFixed(2) })
  }

  handleBack = () => {
    const { activeStep } = this.state;
    //AGDA 29/04/2019
    //Added hadError 
    this.setState({
      activeStep: activeStep - 1, isEdit: true, hadError: false
    });
  };

  handleReset = () => {
    this.clearForm();
  };
  //End Stepper 

  //Inicio Input Tags
  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (selectedItem.length && !inputValue.length && keycode(event) === 'backspace') {
      this.setState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1),
      });
    }
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleDelete = item => () => {
    const selectedItem = [...this.state.selectedItem];
    selectedItem.splice(selectedItem.indexOf(item), 1);

    this.setState({ selectedItem });
  };
  //Fin Input Tags

  //Expandables
  handleChangeExpand = panel => (event, expanded) => {
    switch (panel) {
      case 'panel2':
        this.handleCatalogue('authors', 24);
        break;
      case 'panel3':
        this.handleCatalogue('formats', 9);
        this.handleCatalogue('platforms', 13);
        this.handleCatalogue('audiences', 8);
        this.handleCatalogue('genres', 4);
        this.handleCatalogue('subgenres', 25);
        this.handleCatalogue('themes', 26);
        this.handleCatalogue('motivations', 27);
        this.handleCatalogue('temporalities', 28);
        this.handleCatalogue('environments', 29);
        this.handleCatalogue('schedules', 47);
        break;
      case 'panel4':
        this.handleCatalogue('producers', 31);
        this.handleCatalogue('durations', 23);
        break;
      default:
        break;
    }

    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  //End Expandables

  //Inputs
  //Update validation for inputs
  handleChange = event => {
    let auxElasticData = undefined
    if (!event.target){
      this.setState({ [event.name]: event.value });
      auxElasticData = elasticEquivalents.find(e => e.state === event.name);
    }
    if(event.target){
        this.setState({ [event.target.name]: event.target.value });
        auxElasticData = elasticEquivalents.find(e => e.state === event.target.name);
    }
    //AHDA 26/03/19
    if (auxElasticData) {

      // JCMY 23/07/2019 incidencia 1088
      try {
        let body = Object.assign({}, this.state.bodyElastic);
        body[auxElasticData.elastic] = this.state[auxElasticData.array].find(f => f.value === event.target.value).data;
        this.setState({ bodyElastic: body });
      } catch (error) {

      }

    }
  };

  handleVideoConfig = (e, name) => {
    const temp = this.state.new_materialVideoConfig;
    temp[name] = ["audio", "subtitle"].includes(name) ? e.target.checked : e.target.value;

    if (name === "audio" && e.target.checked === false)
      temp['audioLang'] = undefined;
    if (name === "subtitle" && e.target.checked === false)
      temp['subLang'] = undefined;

    this.setState({ new_materialVideoConfig: temp })
  }

  handleMaterialChange = event => {
    let auxAcceptedFiles = [];

    switch (event.target.value) {
      case 'img':
        auxAcceptedFiles = ["image/jpeg", "image/png", "image/jpg"];
        break;
      case 'video':
      case 'trailer':
        auxAcceptedFiles = ['video/mp4', 'video/MP2T',
          'video/3gpp',
          'video/quicktime',
          'video/x-msvideo',
          'video/x-ms-wmv'];
        break;
      case 'libreto':
        auxAcceptedFiles = [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/pdf',
          ".zip", ".rar", ".7zip"
        ]
        break;
      case 'escaleta':
        auxAcceptedFiles = [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/pdf',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          "image/jpeg", "image/png", "image/jpg",
          ".zip", ".rar", ".7zip"
        ];
        break;
      case 'capitulo1':
        auxAcceptedFiles = [
          'audio/mpeg',
          'audio/mp4',
          'audio/vnd.wav',
          'audio/mp3',
          'video/mp4', 'video/MP2T',
          'video/3gpp',
          'video/quicktime',
          'video/x-msvideo',
          'video/x-ms-wmv',
          ".zip", ".rar", ".7zip"
        ];
        break;
      case 'biblia':
      case 'brochure':
      case 'dialogo':
        auxAcceptedFiles = [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/pdf',
          ".zip", ".rar", ".7zip"
        ];
        break;
      default:
        auxAcceptedFiles = [
          "application/pdf",
          "application/msword",
          "application/vnd.ms-powerpoint",
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];
        break;
    }
    this.setState({
      new_material_type: event.target.value,
      filesMaterial: [],
      acceptedFiles: auxAcceptedFiles.filter(x => this.state.paramFiles.includes(x))
    })
  }

  handleAddChip = (item) => {
    const list = [...this.state.new_tags]
    list.push(item)
    this.setState({ new_tags: list });
  }


  handleDeleteChip = (chip, index) => {
    const list = [...this.state.new_tags]
    list.splice(index, 1);
    this.setState({ new_tags: list });
  }

  handleBlur = (event) => {
    const error = `error${event.target.name}`;
    if (!event.target.value || event.target.value === "") {
      this.setState({ [error]: true });
    } else {
      this.setState({ [error]: false });
    }
  }
  //End inputs

  //Dropzone
  //Limited to one file
  onDrop = (files, type, version) => {
    if (files.length <= 0) {
      this.setState({ openError: true, notfType: "error", notfMesg: type === "pdf" ? "El archivo debe ser un PDF" : "El archivo debe ser un PDF o Word" });
      return false;
    }

    let step = this.state.activeStep === 2 ? "filesDiagram" : this.state.activeStep === 3 ? "filesEvaluation" : "filesMaterial";
    let oldFiles = this.state[step];
    if (oldFiles.length >= 1) {
      this.setState({ openError: true, notfType: "error", notfMesg: "Solo se puede subir un archivo" })
    } else {
      if (version)
        files[0].version = files[0].name.substring(0, files[0].name.lastIndexOf('.')) + "_v" + (this.state.new_EvaluationFile ? this.state.new_EvaluationFile.length + 1 : 1) + files[0].name.substring(files[0].name.lastIndexOf('.'), files[0].name.length)

      if (files.length > 1) {
        files = [files[0]];
      }


      oldFiles = oldFiles.concat(files);
      console.log(files)
      this.setState({ [step]: oldFiles });
    }
  }

  onDropAll = (files) => {

    if (files.length <= 0) {
      this.setState({ openError: true, notfType: "error", notfMesg: "No se pudieron subir los archivos" });
      return false;
    }


    //Validation for one material only 
    //Applies for Brochure, Escaleta and Biblia 
    const { totalFiles } = this.state;
    if (files.length > totalFiles) {
      this.setState({ openError: true, notfType: "warning", notfMesg: "Límite de arcivos por carga superado" });
      return false;
    }
    // if ((new_material_type === "brochure" || new_material_type === "biblia" || new_material_type === "escaleta")
    //   && files.length > 1) {
    //   this.setState({ openError: true, notfType: "error", notfMesg: "El material solo acepta un archivo" });
    //   return false;
    // }

    let oldFiles = this.state.filesMaterial;
    oldFiles = oldFiles.concat(files);
    this.setState({ filesMaterial: oldFiles });
  }

  handleRemove = (file, fileIndex) => {

    let step = this.state.activeStep === 2 ? "filesDiagram" : this.state.activeStep === 3 ? "filesEvaluation" : "filesMaterial";
    const thisFiles = this.state[step];
    // This is to prevent memory leaks.
    window.URL.revokeObjectURL(file.preview);

    thisFiles.splice(fileIndex, 1);
    this.setState({ [step]: thisFiles });
  }
  //End Dropzone

  //Forms
  handledOpenFormAuthor = (item) => {
    this.handleClear('authors');
    if (item) {
      let auxItem = this.state.rawData.find(it => it.id === item.id);
      this.setState({
        openFormAuthor: true,
        selected: item,
        new_author_name: auxItem.id_autor,
        new_author_rol: auxItem.rol
      })
    } else {
      this.setState({ openFormAuthor: true });
    }
  }

  handleSubmitFormAuthor = () => {
    if (!this.state.errornew_author_name && !this.state.errornew_author_rol) {
      //Changes are made locally 
      let auxData = [...this.state.data], auxRawData = [...this.state.rawData];

      //AHDA 22/04/2019
      //Changed validation
      if (!this.state.new_author_name || !this.state.new_author_rol) {

        this.setState({
          errornew_author_name: this.state.new_author_name ? false : true,
          errornew_author_rol: this.state.new_author_rol ? false : true
        });

        return false;
      }


      // JCMY 04/07/2019 incidencia 516 y 1008
      if (this.state.selected) {
        const authorSelected = auxRawData.find(a => a.id == this.state.selected.id);

        if (authorSelected.id_autor == this.state.new_author_name && authorSelected.rol == this.state.new_author_rol) {
          this.setState({ openError: true, notfType: "warning", notfMesg: "El autor ya existe en el listado" })
        } else {
          let auxItem = this.state.rawData.findIndex(it => it.id === this.state.selected.id);
          //Update in raw data
          auxRawData[auxItem].id_autor = this.state.new_author_name;
          auxRawData[auxItem].rol = this.state.new_author_rol;

          //Update in shown data
          auxData[auxItem].name = this.state.authors.find(a => a.value == this.state.new_author_name).label;
          auxData[auxItem].rol = this.state.new_author_rol;
        }

      } else {
        //Generate new object 
        const authorExist = auxRawData.find(it => it.id_autor == this.state.new_author_name);

        if (authorExist == undefined) {

          let auxItem = {
            id: auxRawData.length + 1,
            id_autor: this.state.new_author_name,
            rol: this.state.new_author_rol
          }
          auxItem.id = auxItem.id.toString();
          auxRawData.push(auxItem);

          //New object to show 
          auxItem = {
            id: auxData.length + 1,
            name: this.state.authors.find(a => a.value === this.state.new_author_name).label,
            rol: auxItem.rol
          }

          auxItem.id = auxItem.id.toString();
          auxData.push(auxItem);
        } else {
          this.setState({ openError: true, notfType: "warning", notfMesg: "El autor ya existe en el listado" })
        }

      }

      //Update arrays and clear selected object
      this.setState({ data: auxData, rawData: auxRawData, selected: null, openFormAuthor: false });





    }
  }

  handleDeleteFormAuthors = (item) => {
    let auxData = [...this.state.data], auxRawData = [...this.state.rawData];
    let auxItem = this.state.rawData.findIndex(it => it.id === item.id);
    auxData.splice(auxItem, 1);
    auxRawData.splice(auxItem, 1);
    this.setState({ data: auxData, rawData: auxRawData, selected: null });
  }

  //Characters 
  handledOpenFormCharacters = (item) => {
    this.handleClear('characters');
    //TODO, Bind when has elements
    if (item) {
      this.setState({
        openFormCharacters: true,
        selected: item,
        new_characterName: item.nombre,
        new_characterAge: item.edad,
        new_characterPsychology: item.psicologia
      })
    } else {
      this.setState({ openFormCharacters: true });
    }
  }

  handleSubmitCharacters = () => {
    if (!this.state.errornew_characterName && !this.state.errornew_characterPsychology) {

      //AHDA 22/04/2019
      //Changed validation
      if (!this.state.new_characterName || !this.state.new_characterPsychology) {
        this.setState({
          errornew_characterName: this.state.new_characterName ? false : true,
          errornew_characterPsychology: this.state.new_characterPsychology ? false : true
        })
        return false;
      }

      //Changes are made locally 
      let auxData = [...this.state.new_characters];
      let auxItem = {
        nombre: this.state.new_characterName,
        edad: this.state.new_characterAge,
        psicologia: this.state.new_characterPsychology,
      }

      if (this.state.selected) {
        let auxIndex = this.state.new_characters.findIndex(c => c.id === this.state.selected.id);
        auxItem.id = this.state.selected.id;
        auxData[auxIndex] = auxItem;
      } else {
        auxItem.id = auxData.length + 1
        auxData.push(auxItem);
      }

      // JCMY 26/03/2019
      //Limpia datos tras agregar personaje
      this.setState({ new_characters: auxData, selected: null, openFormCharacters: false, new_characterName: "", new_characterAge: "", new_characterPsychology: "" });
    }
  }

  handleDeleteCharacters = (item) => {
    let auxData = [...this.state.new_characters]
    let auxIndex = auxData.findIndex(c => c.id === item.id);
    auxData.splice(auxIndex, 1);
    this.setState({ new_characters: auxData, selected: null });
  }

  handleUpdateCharacters = (evt, updated) => {
    this.setState({ new_characters: [...updated] })
  }

  //Material 
  handledOpenFormMaterial = () => {
    this.handleClear('material');

    this.setState({ openFormMaterial: true });
  }

  handleOpenEdit = (data, type, obj) => {
    this.setState({
      openEdit: true,
      new_material_type: type,
      selectedMaterial: [data, type, obj],
      new_materialDescription: data.description,
      new_materialAmount: data.amount,
      new_materialVideoConfig: data.video_config || {}
    });
  }

  handleSubmitMaterial = () => {

    //AHDA 19/06/2019: Changed validation 
    const hasMaterial = this.state.new_material_type ? true : false;
    const hasLanguage = this.state.new_idioma ? true : false;

    if (!hasMaterial || !hasLanguage) {
      if (hasMaterial) {
        if (this.state.new_material_type !== "img") {
          this.setState({ errornew_material_type: !hasMaterial, errornew_idioma: !hasLanguage });
          return false;
        } else {
          this.setState({ errornew_material_type: false, errornew_idioma: false });
        }
      } else {
        this.setState({ errornew_material_type: !hasMaterial, errornew_idioma: !hasLanguage });
        return false;
      }
    }

    if (!this.state.errornew_material_type && !this.state.errornew_idioma) {

      //Validate required fields 
      //AHDA 22/04/2019
      //Changed validation
      /*if (!this.state.new_material_type || !this.state.new_idioma) {
        this.setState({
          errornew_material_type: this.state.new_material_type ? false : true,
          errornew_idioma: this.state.new_idioma ? false : true 
        })
        return false; 
      }*/

      const { filesMaterial, workId, new_idioma, new_material_type, new_materialDescription, new_materialAmount } = this.state;

      //Validate files array
      if (filesMaterial.length <= 0) {
        this.setState({ openError: true, notfType: "error", notfMesg: "Debes seleccionar al menos un archivo" });
        return false;
      }

      switch (new_material_type) {
        case "libreto":
          let auxScripts = [...this.state.new_mScripts];
          let auxScript = auxScripts.find(s => s.language_id.toString() === new_idioma);
          let auxIndex = -1;

          if (auxScript) {

            auxScript.missing_chapters = this.state.new_materialMissing;
            auxIndex = auxScripts.findIndex(s => s.language_id.toString() === new_idioma);

            if (!auxScript.new) {

              let body = {
                work_id: auxScript.work_id,
                language_id: auxScript.language_id,
                missing_chapters: this.state.new_materialMissing,
                total_chapters: this.state.new_chapter_number
              }

              apiPOST('/content/updatescripts', body).then(response => {
              }).catch(err => {
                console.log(err);
              });
            }
          }
          else {
            auxScript = {
              works_language_Id: auxScripts.length + 1,
              work_id: workId,
              language_id: new_idioma,
              language: this.state.languages.find(l => l.value === new_idioma).data,
              missing_chapters: this.state.new_materialMissing,
              total_chapters: this.state.new_chapter_number,
              status: 1,
              new: true,
              scripts: []
            }
          }
          for (let i = 0; i < filesMaterial.length; i++) {
            let auxFile = {
              id_material_multimedia: auxScript.scripts.length + 1,
              tipo_archivo: new_material_type,
              ruta: undefined,
              id_obra: workId,
              id_idioma: new_idioma,
              file: filesMaterial[i],
              idioma: auxScript.language,
              language: auxScript.language,
              creation_date: "Hace un momento",
              new: true
            }
            auxScript.scripts.push(auxFile);
          }

          if (auxIndex < 0) {
            auxScripts.push(auxScript);
          }
          else {
            auxScripts[auxIndex] = auxScript;
          }


          this.setState({ new_mScripts: auxScripts, openFormMaterial: false, filesMaterial: [] })
          break;
        case "video":
        case "capitulo1":
        case "trailer":
          let auxVideos = [...this.state.new_mVideos];

          // JCMY 04/07/2019 incidencia 453
          for (let i = 0; i < filesMaterial.length; i++) {
            let auxVideo = {
              id_material_multimedia: auxVideos.length + 1,
              tipo_archivo: "video",
              ruta: undefined,
              id_obra: workId,
              id_idioma: new_idioma,
              idioma: (this.state.languages.find(s => s.value == new_idioma)).data,
              file: filesMaterial[i],
              new: true
            }

            auxVideos.push(auxVideo);
          }
          this.setState({ openFormMaterial: false, filesMaterial: [], new_mVideos: auxVideos });
          break;
        case "img":
          let auxImages = [...this.state.new_mImages];
          for (let i = 0; i < filesMaterial.length; i++) {
            let auxImage = {
              id_material_multimedia: auxImages.length + 1,
              tipo_archivo: "img",
              ruta: undefined,
              id_obra: workId,
              id_idioma: new_idioma,
              file: filesMaterial[i],
              new: true
            }
            auxImages.push(auxImage);
          }
          this.setState({ openFormMaterial: false, filesMaterial: [], new_mImages: auxImages });
          break;
        default:
          let auxOther = [...this.state.new_mOthers];
          let auxItem = auxOther.find(m => m.type === new_material_type);
          let auxIdx = -1;

          ///File type
          if (auxItem) {
            auxIdx = auxOther.findIndex(m => m.type === new_material_type);
          } else {
            auxItem = {
              type: new_material_type,
              files: []
            }
          }

          ///Language 
          const auxMatIdx = auxItem.files.findIndex(m => m.id_idioma == new_idioma);
          if (auxMatIdx >= 0) {
            auxItem.files[auxMatIdx].file = filesMaterial[0];
            auxItem.new = true;
          } else {
            for (let i = 0; i < filesMaterial.length; i++) {
              let auxFile = {
                id_material_multimedia: auxItem.files.length + 1,
                tipo_archivo: new_material_type,
                ruta: undefined,
                id_obra: workId,
                id_idioma: new_idioma,
                language: this.state.languages.find(l => l.value === new_idioma).data,
                file: filesMaterial[i],
                name: filesMaterial[i].name,
                new: true
              }
              auxItem.files.push(auxFile);
            }
          }

          if (auxIdx < 0)
            auxOther.push(auxItem);
          else
            auxOther[auxIdx] = auxItem;
          this.setState({ openFormMaterial: false, filesMaterial: [], new_mOthers: auxOther });
          break;
      }

    }
  }

  //Recieves an array for scripts
  handleDeleteMaterial = (items, type, array) => {
    if (!this.props.canEdit && !this.props.canAddMaterial) {
      this.setState({
        openError: true,
        notfType: "error",
        notfMesg: `No cuentas con los permisos para editar la obra`
      });
      return;
    }

    let auxData = [...this.state[array]];

    switch (type) {
      case "libreto":


        let auxScriptsIdx = auxData.findIndex(script => script.language_id.toString() === items[0].id_idioma.toString());
        let auxScripts = auxData[auxScriptsIdx];
        let errorScripts = false;

        for (let i = 0; i < items.length; i++) {
          let idx = auxScripts.scripts.findIndex(s => s.id_material_multimedia == items[i].id_material_multimedia);

          if (idx > -1) {
            if (auxScripts.scripts[idx].new) {
              auxScripts.scripts.splice(idx, 1);

              if (i === items.length - 1) {
                auxData[auxScriptsIdx] = auxScripts;
                this.setState({
                  [array]: auxData,
                  openError: errorScripts,
                  notfType: "error",
                  notfMesg: `Algunos archivos no se eliminaron correctamente`
                });
              }

            } else {
              deleteFileComplete('/content/deletemultimedia', { multimedia_id: auxScripts.scripts[idx].id_material_multimedia }, auxScripts.scripts[idx].ruta)
                .then(() => {
                  const bodyAudit = {
                    id_usuario: returnCurrentUserId(),
                    user_action: 24,
                    work_id: auxScripts.work_id,
                    portal: 'acervo',
                    screen: "confLiteraryWorks"
                  }
                  apiPOST('/audit/add', bodyAudit)
                  // console.log(auxScripts.scripts);
                  // console.log(idx);
                }).catch(err => errorScripts = true).finally(() => {
                  if (i === items.length - 1) {
                    auxData[auxScriptsIdx] = auxScripts;
                    this.setState({
                      [array]: auxData,
                      openError: errorScripts,
                      notfType: "error",
                      notfMesg: `Algunos archivos no se eliminaron correctamente`
                    });
                  }
                });

              auxScripts.scripts.splice(idx, 1);
            }
          }
        }
        break;
      case "img":
      case "video":
        const auxItem = items[0];
        const auxIndex = auxData.findIndex(file => file.id_material_multimedia === auxItem.id_material_multimedia);

        if (auxItem.new) {
          auxData.splice(auxIndex, 1);
          this.setState({ [array]: auxData });
        } else {
          //TODO : Delete from s3 and RDS
          deleteFileComplete('/content/deletemultimedia', { multimedia_id: auxItem.id_material_multimedia }, auxItem.ruta).then(response => {
            auxData.splice(auxIndex, 1);
            this.setState({ [array]: auxData });
            const bodyAudit = {
              id_usuario: returnCurrentUserId(),
              user_action: 25,
              work_id: this.state.workId,
              portal: 'acervo',
              screen: "confLiteraryWorks"
            }
            apiPOST('/audit/add', bodyAudit)
          }).catch(err => {
            this.setState({
              openError: true,
              notfType: "error",
              notfMesg: `No se pudo eliminar el archivo`
            })
          });
        }
        break;
      default:
        const auxMatItem = items[0];

        const auxMaterialData = auxData.find(material => material.type === type);
        const auxMaterialIndex = auxData.findIndex(material => material.type === type);

        const auxMatIndex = auxMaterialData.files.findIndex(m => m.id_material_multimedia === auxMatItem.id_material_multimedia);

        if (auxMatItem.new) {
          auxMaterialData.files.splice(auxMatIndex, 1);
          auxData[auxMaterialIndex] = auxMaterialData;
          this.setState({ [array]: auxData });
        } else {
          //TODO: delte from s3 and RDS
          deleteFileComplete('/content/deletemultimedia', { multimedia_id: auxMatItem.id_material_multimedia }, auxMatItem.ruta).then(response => {
            auxMaterialData.files.splice(auxMatIndex, 1);
            auxData[auxMaterialIndex] = auxMaterialData;
            this.setState({ [array]: auxData });
          }).catch(err => {
            this.setState({
              openError: true,
              notfType: "error",
              notfMesg: `No se pudo eliminar el archivo`
            })
          });
        }
        break;
    }
  }

  handleSubmitEdit = () => {
    if (!this.state.new_materialDescription || this.state.new_materialDescription === "") {
      this.setState({ openError: true, notfType: "warning", notfMesg: `Agrega por lo menos una descripción` });
      return;
    }
    const item = this.state.selectedMaterial[0];
    const type = this.state.selectedMaterial[1];
    const array = this.state.selectedMaterial[2];

    let auxData = [...this.state[array]];

    switch (type) {
      case "libreto":
        let auxScriptsIdx = auxData.findIndex(script => script.language_id.toString() === item.id_idioma.toString());
        let auxScripts = auxData[auxScriptsIdx];

        let idx = auxScripts.scripts.findIndex(s => s.id_material_multimedia == item.id_material_multimedia);

        if (idx > -1) {
          if (auxScripts.scripts[idx].new) {
            auxScripts.scripts[idx].description = this.state.new_materialDescription
            auxScripts.scripts[idx].amount = this.state.new_materialAmount
            auxData[auxScriptsIdx] = auxScripts;

          }
        }

        break;
      case "img":
      case "video":
        if (type === "video") {
          if (this.state.new_materialVideoConfig['audio'] === true && !this.state.new_materialVideoConfig['audioLang']) {
            this.setState({ openError: true, notfType: "warning", notfMesg: `Selecciona el idioma del audio` });
            return;
          }
          if (this.state.new_materialVideoConfig['subtitle'] === true && !this.state.new_materialVideoConfig['subLang']) {
            this.setState({ openError: true, notfType: "warning", notfMesg: `Selecciona el idioma del subtítulo` });
            return;
          }

        }
        const auxIndex = auxData.findIndex(file => file.id_material_multimedia === item.id_material_multimedia);
        if (item.new) {
          auxData[auxIndex].description = this.state.new_materialDescription
          auxData[auxIndex].amount = this.state.new_materialAmount;
          auxData[auxIndex].video_config = this.state.new_materialVideoConfig;

        }
        break;
      default:

        const auxMaterialData = auxData.find(material => material.type === type);
        const auxMaterialIndex = auxData.findIndex(material => material.type === type);

        const idxMat = auxMaterialData.files.findIndex(m => m.id_material_multimedia === item.id_material_multimedia);

        if (item.new) {
          auxMaterialData.files[idxMat].description = this.state.new_materialDescription
          auxMaterialData.files[idxMat].amount = this.state.new_materialAmount
          auxData[auxMaterialIndex] = auxMaterialData;

        }
        break;

    }
    this.setState({
      [array]: auxData,
      selectedMaterial: null,
      openEdit: false,
      new_material_type: undefined
    });
    this.handleClear('edit');
  }

  handledCloseForm = () => {
    this.setState({ openFormAuthor: false, openFormCharacters: false, openFormMaterial: false, selected: null });
  }

  handleCloseEdit = () => {
    this.setState({ openEdit: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
  }

  handleClear = (form) => {
    switch (form) {
      case 'authors':
        this.setState({
          new_author_name: undefined,
          new_author_rol: undefined,
          errornew_author_name: false,
          errornew_author_rol: false
        });
        break;
      case 'characters':
        this.setState({
          new_characterName: "", //AHDA 03/06/2019
          new_characterAge: "", //AHDA 03/06/2019
          new_characterPsychology: "", //AHDA 03/06/2019
          errornew_characterName: false,
          errornew_characterAge: false,
          errornew_characterPsychology: false,
        });
        break;
      case 'material':
        this.setState({
          new_material_type: undefined,
          new_idioma: undefined,
          errornew_idioma: false,
          errornew_material_type: false,
          filesMaterial: [],
          new_materialMissing: undefined
        })
        break;
      case 'edit':
        this.setState({
          new_materialDescription: "",
          new_materialAmount: "",
          new_materialVideoConfig: {}
        })
        break;
      default:
        break;
    }
  }
  //End Forms 

  ///////////////////////////////////// Helpers
  handleCatalogue = (catalogue, idCatalogue) => {

    if (this.state[catalogue].length <= 0) {
      //AHDA 16/05/2019: Added valdation for refreshing
      apiPOST('/catalogue/get', { idCatalogo: idCatalogue }, true).then(
        response => {
          if (response.status === 200) {

            this.setState({ [catalogue]: response.data });
          }
        }
      )
    }
  }

  // JCMY 24/06/2019
  getCode = () => {

    let code = 0;

    //AHDA 26/06/2019: Added validation for editing

    // JCMY 04/07/2019 incidecnia 1016 y 1007
    // if(this.state.isCompleteEdit){
    //   code = this.state.new_code;
    // }else{

    let id_obra_primigenia = 0;
    try {
      id_obra_primigenia = (this.state.worksCatalog.find(obj => obj.label === this.state.new_title_originalVersion)).id;
    } catch (error) {
      //console.log(error)
    }

    // JCMY 24/06/2019
    if (this.state.new_type == 'primigenia') {
      // else{
      const lastPrimordialCode = this.state.lastPrimordialCode
      code = lastPrimordialCode[0] ? lastPrimordialCode[0].lastCode : 10000
      // }
      //isanchez 30.07.2019 el número de folio no debe cambiar al editar la obra

      if (this.state.isEdit && this.state.new_code !== null && id_obra_primigenia === 0) {
        code = this.state.new_code;
      }

    } else {

      if (id_obra_primigenia == 0) {
        code = '99999'
      } else {
        code = (this.state.worksCatalog.find(obj => obj.label === this.state.new_title_originalVersion)).code
      }
    }



    // }
    return code;
  }

  getFileNumber = () => {
    try {
      let auxType = !this.state.new_type ? '' : this.state.new_type === 'primigenia' ? 'P' : 'A';
      let auxCode = !this.state.new_country_origin ? '' : this.state.countries.find(c => c.value === this.state.new_country_origin) ? this.state.countries.find(c => c.value === this.state.new_country_origin).code : '';
      let code = this.getCode();
      // JCMY 24/06/2019
      let auxFilenumber = `${this.state.new_title}_${code == 99999 ? 'ASP' : auxType}_${auxCode}_${code}.${this.state.new_type === 'primigenia' ? '00' : this.state.new_version_number}_${this.state.workId}`;
      return auxFilenumber;
    } catch (err) {
      console.log("getFileNumber", err)
    }
  }

  addProducers = (workId) => {

    const { isEdit } = this.state;
    //Call to production 
    let bodyProducers = { producers: [], work_id: workId };

    for (let x in this.state.new_producers) {
      let auxItem = {
        work_id: workId,
        producer_id: Number.parseInt(this.state.new_producers[x])
      }

      bodyProducers.producers.push(auxItem);
    }

    return new Promise(function (resolve, reject) {
      //AHDA 16/05/2019: Added valdation for refreshing
      apiPOST(!isEdit ? '/content/addproducer' : '/content/updateproducer', bodyProducers, true).then(
        response => {
          if (response.status === 200)
            resolve(response);
          else
            reject(response);
        }
      ).catch(
        err => {
          console.log(err);
          reject(err);
        }
      );
    })
  }

  addAdaptations = (workId, body) => {

    const { isEdit, original_versions } = this.state;

    let bodyAdaptations = { adaptations: [], dropadaptations: [] }

    for (let x in this.state.new_versions) {
      //AHDA 11/08/2019
      let auxItem = {
        idPrimigenia: body.tipo_obra === "primigenia" ? Number.parseInt(workId) : Number.parseInt(this.state.new_versions[x]),
        idAdaptacion: body.tipo_obra === "primigenia" ? Number.parseInt(this.state.new_versions[x]) : Number.parseInt(workId)
      }
      bodyAdaptations.adaptations.push(auxItem);
    }

    if (isEdit) {
      if (original_versions.length > 0) {
        for (let x in original_versions) {
          if (this.state.new_versions.findIndex(a => a == original_versions[x]) === -1) {
            let auxItem = {
              idPrimigenia: body.tipo_obra === "primigenia" ? workId : Number.parseInt(original_versions[x]),
              idAdaptacion: body.tipo_obra === "primigenia" ? Number.parseInt(original_versions[x]) : workId
            }
            bodyAdaptations.dropadaptations.push(auxItem);
          }
        }
      } else {
        bodyAdaptations.dropadaptations = bodyAdaptations.adaptations;
      }
    }


    return new Promise(function (resolve, reject) {
      //AHDA 16/05/2019: Added valdation for refreshing
      apiPOST(!isEdit ? '/content/addadaptation' : '/content/updateadaptation', bodyAdaptations, true).then(
        response => {
          if (response.status === 200)
            resolve(response);
          else
            reject(response);
        }
      ).catch(
        err => {
          console.log(err);
          reject(err);
        }
      );
    })
  }

  //Global for editing pourposes
  clearForm = () => {
    this.setState({
      //Stepper
      activeStep: 0,
      //Expandables
      expanded: 'panel1',
      //Files
      filesDiagram: [],
      filesEvaluation: [],
      filesMaterial: [],
      data: [], //New Authors
      rawData: [],
      new_versions: [],
      new_producers: [],
      new_characters: [],
      new_scripts: [],
      new_videos: [],
      new_otherMaterial: [],
      new_type: undefined,
      new_fileNumber: '',
      new_title: '',
      new_title_originalVersion: '',
      new_material_type: '',
      new_country_origin: undefined,
      new_version_number: '00',
      new_produced: undefined, // (initial value, so nothing is selected)
      new_empresa_proveedor: undefined,
      new_clasification: undefined,
      new_production: undefined,
      new_source: undefined,
      new_chap_duration: undefined,
      new_format: undefined,
      new_genre: undefined,
      new_aired_duration: undefined,
      new_chapter_number: undefined,
      new_productionYear: '',
      new_airedChapters: '',
      new_logline: '',
      new_shortSynopsis: '',
      new_long_synopsis: '',
      new_author_name: undefined,
      new_theme: undefined,
      new_english_title: undefined,
      new_title_other: undefined,
      new_schedule: undefined,
      new_environment: undefined,
      new_suggested_schedules: [],
      new_motivation: undefined,
      new_audience: undefined,
      new_subgenre: undefined,
      new_temporality: undefined,
      new_platform: undefined,
      new_tags: [],
      new_author_rol: undefined,
      //Material
      new_mScripts: [],
      new_mImages: [],
      new_mVideos: [],
      new_mOthers: [],
      new_CharacterDiagram: undefined,
      new_EvaluationFile: undefined,
      //Forms
      selected: null,
      workId: 0,
      isEdit: false,
      body: {}
    });
  }

  handleDeleteFile = (file, type, object) => {

    this.setState({ isLoading: true });
    deleteFileComplete('/content/deletemultimedia', { multimedia_id: file.id_material_multimedia }, file.ruta)
      .then(response => {
        const temp = this.state[object];
        temp.splice(file.key, 1);
        this.setState({
          [object]: temp,
          isLoading: false,
          openError: true,
          notfType: "success",
          notfMesg: `Se eliminó correctamente el archivo`
        });
      }).catch(err => {
        this.setState({
          isLoading: false,
          openError: true,
          notfType: "error",
          notfMesg: `No se pudo eliminar el archivo`
        })
      })
  }

  handleOpenFile = (file) => {
    if (file) {
      const extension = file.name.split(".").pop();
      if (["zip", "rar", "7zip"].includes(extension)) {
        this.setState({
          openError: true,
          notfType: "error",
          notfMesg: "Vista no disponible para archivos zip, rar, 7zip"
        });
        return false;
      }

      const auxUrl = encodeURIComponent(file.ruta);
      window.open(`/file?doc=${auxUrl}`, "_blank");


    }
  }
  ///////////////////////////////////// End helpers

  //Life cycle
  componentDidMount() {
    //////////////// Get Initial Catalogues
    this.handleCatalogue('lastPrimordialCode', 43);
    // JCMY 20/06/2019
    //titles works
    this.handleCatalogue('worksCatalog', 42);
    //JCMY 24/06/2019
    //Companies/Providers
    this.handleCatalogue('companies', 7);
    //Sources 
    this.handleCatalogue('sources', 11);
    //Countries
    this.handleCatalogue('countries', 3);
    //Durations
    this.handleCatalogue('durations', 23);
    this.handleCatalogue('territories', 48);
    this.handleCatalogue('titles', 30);
    this.handleCatalogue('roles_escritores', 49);
    this.handleCatalogue('escritores', 18);
    //Get Languages Catalogue
    this.handleCatalogue('languages', 32);

    this.handleCatalogue('producerUsers', 17);

    apiPOST("/params/get", {
      portal: 1
    }).then(response => {
      let temp = [];
      response.data[1] && JSON.parse(response.data[1].value).forEach(item => {
        switch (item.type) {
          case 'PDF':
            temp = [...temp, 'PDF', 'application/pdf'];
            break;
          case 'Power Point':
            temp = [...temp, 'Power Point',
              'application/vnd.ms-powerpoint',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            ];
            break;
          case 'Word':
            temp = [...temp, 'Word',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/msword'
            ];
            break;
          case 'Video':
            temp = [...temp, 'Video',
              'video/mp4',
              'video/MP2T',
              'video/3gpp',
              'video/quicktime',
              'video/x-msvideo',
              'video/x-ms-wmv'];
            break;
          case 'Imagen':
            temp = [...temp, "image/jpeg", "image/png", "image/jpg"];
            break;
          case 'Comprimido':
            temp = [...temp, ".zip", ".rar", ".7zip"];
            break;
          default:
            break;
        }
      });
      const inter = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].filter(x => temp.includes(x));
      this.setState({
        totalFiles: response.data[2] ? JSON.parse(response.data[2].value).total : 1,
        paramFiles: temp,
        params: JSON.parse(response.data[1].value),
        acceptedFilesEval: inter
      });
    })

    //////////////// End get initial catalogues 

    //AHDA 20/06/2019: Added validation for spaces
    ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
      if (/^\s+$/i.test(value)) {
        return false;
      }
      return true;
    });

    //AHDA 26/06/2019: Added validation for adaptation
    ValidatorForm.addValidationRule('adaptationNumber', (value) => {
      if (this.state.new_type === "adaptacion") {
        if (value === "00") {
          return false;
        }
      }
      return true;
    })

    let { workId } = this.props;

    if (workId > 0) {

      //(for editing)
      this.handleCatalogue('formats', 9);
      this.handleCatalogue('producers', 31);

      this.setState({ isLoading: true });

      apiPOST('/content/getDetail', { workId: workId }).then(
        response => {
          if (response.status === 200) {
            const { data } = response;
            //AHDA 29/04/2019
            //Added isCompleteEdit

            let otherPrimigenia = new Array();
            let delOtherPrimigenia = new Array();
            if (data.otherPrimigenia && data.otherPrimigenia.length > 0) {
              data.otherPrimigenia.map(
                item => {
                  delOtherPrimigenia.push(item.id_obras);
                  otherPrimigenia.push(item.titulo_original)
                }
              )
            }


            const [SipCorLength, editorStateSipCor] = this.convertContent('sinopsis_corta', data.new_shortSynopsis);
            const [, editorStateSipLar] = this.convertContent('sinopsis_larga', data.new_long_synopsis);

            if (data.new_vigencia) {
              data.new_vigencia.options = JSON.parse(data.new_vigencia.options);
            }
            this.setState({
              data: data.data ? data.data : [],
              rawData: data.rawData ? data.rawData : [],
              new_versions: data.new_versions,
              new_equipos: data.new_equiposLit || [],
              new_vigencia: data.new_vigencia,
              new_moreInfo: data.new_moreInfo || {},
              original_versions: data.new_versions,
              new_producers: data.new_producers,
              new_characters: data.new_characters,
              new_type: data.new_type,
              new_fileNumber: data.new_fileNumber,
              new_title: data.new_title,
              new_title_originalVersion: data.new_title_originalVersion,
              new_country_origin: data.new_country_origin,
              new_version_number: data.new_version_number,
              new_produced: data.new_produced,
              new_empresa_proveedor: data.new_empresa_proveedor,
              new_clasification: data.new_clasification,
              new_production: data.new_production,
              new_source: data.new_source,
              new_chap_duration: data.new_chap_duration,
              new_format: data.new_format,
              new_genre: data.new_genre,
              new_aired_duration: data.new_aired_duration,
              new_chapter_number: data.new_chapter_number,
              new_productionYear: data.new_productionYear,
              new_airedChapters: data.new_airedChapters,
              new_logline: this.converLogline(data.new_logline),
              // new_shortSynopsis: data.new_shortSynopsis,
              // new_long_synopsis: data.new_long_synopsis,
              new_theme: data.new_theme,
              new_english_title: data.new_english_title,
              new_title_other: data.new_title_other,
              new_schedule: data.new_schedule,
              new_environment: data.new_environment,
              new_suggested_schedules: data.new_suggested_schedules ? data.new_suggested_schedules.map(e => e.id_horario_sugerido.toString()) : [],
              new_motivation: data.new_motivation,
              new_audience: data.new_audience,
              new_subgenre: data.new_subgenre,
              new_temporality: data.new_temporality,
              new_platform: data.new_platform,
              new_tags: data.new_tags,
              new_mScripts: data.new_mScripts,
              new_mImages: data.new_mImages,
              new_mVideos: data.new_mVideos.map(item => {
                item.video_config = item.video_config ? JSON.parse(item.video_config) : {}
                return item;
              }),
              new_mOthers: data.new_mOthers,
              workId: workId,
              //new_ev_id: data.new_ev_id ? data.new_ev_id : 0,
              // new_ev_comments: data.new_ev_comments,
              // new_ev_rating: data.new_ev_rating,
              // new_ev_schedule: data.new_ev_schedule,
              // new_ev_share: data.new_ev_share,
              new_CharacterDiagram: data.new_CharacterDiagram.length > 0 ? data.new_CharacterDiagram[0] : undefined,
              new_EvaluationFile: data.new_evaluations, //data.new_EvaluationFile.length > 0 ? data.new_EvaluationFile : undefined,
              isEdit: true,
              isCompleteEdit: true,
              isLoading: false,
              asigned_producer: data.asigned_producer ? data.asigned_producer : 0,
              disabledCode: data.new_type === "primigenia" ? true : false, //AHDA 26/06/2019
              new_code: data.new_code, //AHDA 26/06/2019
              new_fileNumber: data.new_fileNumber, //AHDA 26/06/2019,
              editorSipnosisCorta: data.new_shortSynopsis,
              editorSipnosisLarga: data.new_long_synopsis,
              editorStateSipCor,
              editorStateSipLar,
              SipCorLength,
              arrayOtherPrimigenia: otherPrimigenia,
              arrayDelOtherPrimigenia: delOtherPrimigenia,
              new_shortSynopsis: data.new_shortSynopsis,
              new_long_synopsis: data.new_long_synopsis,
            }, () => {
              console.log("Editing: ", workId);
              console.log(editorStateSipLar)
            });
          } else {
            this.props.openFatalError();
          }
        }
      ).catch(err => {
        console.log(err);
        this.props.openFatalError();
      })
    }
  }

  converLogline(data) {
    let a = "";
    if (!data) {
      return "";
    }
    try {
      a = JSON.parse(`${data}`);
      let text = "";
      if (Array.isArray(a.blocks)) {
        a.blocks.forEach(b => (b.text) ? text += (b.text + "\n\n") : null);
      }
      return text;
    } catch (error) {
      return data;
    }
  }

  convertContent = (name, data) => {
    let a = "";
    if (!data) {
      return [0, null];
    }
    try {
      a = JSON.parse(`${data}`);
    } catch (error) {
      console.log(`${name} Editor, is not JSON, will convert`);
    }
    if (a && (typeof a === "object")) {
      const contentBlock = convertFromRaw(a);
      const editorState = EditorState.createWithContent(contentBlock);
      const editorLength = this.getEditorContentLength(a);
      return [editorLength, editorState];
    } else if (data.length > 0) {
      if (data.includes('{"blocks"'))
        data = data.split(",")[1].split(":")[1].replace(/"/g, "");

      const rawContent = {
        "blocks": [],
        "entityMap": {}
      }
      data.split("<br>").forEach((e, k) => {
        rawContent.blocks.push({ "key": "4tt3f" + k, "text": e.replace(/<\/?[^>]+(>|$)/g, " ").replace(/&nbsp;/g, " ").trim(), "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} })
      });
      const contentBlock = convertFromRaw(rawContent);
      const editorState = EditorState.createWithContent(contentBlock);
      const editorLength = this.getEditorContentLength(a);

      return [editorLength, editorState];
    }

    return [0, null];
  }

  handleBeforeInput = (name) => {
    if (this.getEditorContentLength(convertToRaw(this.state[`${name}EditorState`].getCurrentContent())) >= (name == "logline" ? 1000 : 15000)) {
      this.setState({ openAlert: true, variant: "warning", message: `El límite de caracteres es de ${(name == "logline" ? '1,000' : '15,000')}` });
      return 'handled';
    }
  };

  getEditorContentLength = (data) => {
    let length = 0;
    if (isObject(data) && Array.isArray(data.blocks)) {
      data.blocks.forEach(b => (b.text) ? length += b.text.length : null);
    }

    return length;
  }

  // onEditorStateChange = editorStateLogline => {
  //   this.setState({
  //     editorStateLogline,
  //   }, () => {
  //    console.log("editor logline", this.state.editorStateLogline.getCurrentContent()) 
  //   });
  // };

  onEditorStateChangeSipCor = editorStateSipCor => {
    const currentContent = editorStateSipCor.getCurrentContent();
    const converted = convertToRaw(currentContent);
    const editorString = JSON.stringify(converted).replace(/'/g, "");
    let length = this.getEditorContentLength(converted);

    if (length > 15000) {
      return false;
    }

    this.setState({
      editorStateSipCor,
      editorSipnosisCorta: editorString,
      SipCorLength: length
    });
  };

  onEditorStateChangeSipLar = editorStateSipLar => {
    const currentContent = editorStateSipLar.getCurrentContent();
    const converted = convertToRaw(currentContent);
    const editorString = JSON.stringify(converted).replace(/'/g, "");
    //let length = this.getEditorContentLength(converted);

    this.setState({
      editorStateSipLar,
      editorSipnosisLarga: editorString,
      //SipLarLength:length
    });
  };

  handleChangeMaterialMissing = (newValue, script) => {
    let aux = new Array();
    aux = this.state.new_mScripts;
    let indexModify = aux.findIndex(x => x.works_language_Id === script.works_language_Id);
    if (indexModify !== -1) {
      aux[indexModify].missing_chapters = newValue == '' ? 0 : newValue;
    }
    this.setState(
      {
        auxMaterialMissing: aux
      }
    )
  }

  render() {

    const { classes, handleCloseStep, canAddMaterial, canEvaluate, canEdit, canViewSinopsis, canViewPersonaje, canViewVigencia, canViewEquipoLit, canViewMoreInfo, canMarkProspect, canAssignProject, canUnlockWork, canEndWork } = this.props;
    const { lang } = this.context;
    //Stepper
    const steps = [...this.state.steps];
    const {
      activeStep, expanded, altLabel,
      filesDiagram, filesEvaluation, titles, filesMaterial } = this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: (ITEM_HEIGHT * 4.5) + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const { dense, secondary, workId, totalFiles } = this.state;

    //other versions
    const getVersionsExpandablePanel = () => {
      if ((this.state.new_produced === 0 && (this.state.new_type === "primigenia" && this.state.new_type === "adaptacion")) || !this.state.new_type) {
        return null;
      }
      else {
        return (
          <ExpansionPanel expanded={expanded === 'panel5'} onChange={this.handleChangeExpand('panel5')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
              <Typography className={classes.headingExpand}>Otras Versiones</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={16}>
                {/*List of other versions*/}
                <Grid item lg={12} md={12} sm={12} xs={12}>                    
                    <SelectNew
                      label="Versiones"
                      name="new_versions"
                      isClearable
                      defaultValue={this.state.new_versions}
                      classes={classes}
                      styles={selectStyles}
                      options={this.state.titles.filter(x => x.value !== workId)}
                      value={this.state.new_versions}
                      onChange={(e) => {
                        const customEvent = {
                          target: {
                            name: "new_versions",
                            value: e.map(x => x.value),
                          }
                        }
                        this.handleChange(customEvent)
                      }}
                      isMulti
                    />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      }
    }

    const { theme } = this.props;
    //other versions Select
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: "black",
        "& input": {
          font: "inherit",
        },
      }),
    };
    
    const getMorePrimigeniasForAdaptation = () => {
      if (((this.state.new_type === "primigenia")) || !this.state.new_type) {
        return null;
      } else {
        return (
          <ExpansionPanel expanded={expanded === 'panel6'} onChange={this.handleChangeExpand('panel6')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
              <Typography className={classes.headingExpand}>Otras Obras Primigenias</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={16}>
                {/*List of other versions*/}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.chips}>

                    {this.state.arrayOtherPrimigenia.map((value, idx) => {
                      return <div>
                        <Chip key={`chip_titlesPrimigenia_${idx}`} label={value ? value : '-'} className={classes.chip} />
                        <IconButton aria-label="Delete" onClick={() => this.handleDeleteOtherPrimigenia(value)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    })}
                  </div>
                  <Downshift selectedItem={this.state.new_title_otherPrimigenia} onSelect={this.handleSaveToArray} onStateChange={this.handleStateChangePrimigenia}>
                    {({
                      getInputProps,
                      getItemProps,
                      isOpen,
                      inputValue,
                      selectedItem,
                      highlightedIndex
                    }) => (
                      <div className={classes.formControl}>
                        {renderInput({
                          fullWidth: true,
                          classes,
                          // CH 26/06/2019 Se acomoda label
                          label: "Título de la obra primigenia",
                          InputLabelProps: { shrink: true },
                          InputProps: getInputProps({
                            placeholder: "Ej. Senda Prohibida",
                            id: 'new_title_otherPrimigenia',
                            name: 'new_title_otherPrimigenia',
                          }),
                        })}
                        {isOpen ? (
                          <Paper className={classes.paper} square>
                            {getSuggestions(inputValue, this.state.worksCatalog).map((suggestion, index) =>
                              renderSuggestion({
                                suggestion,
                                index,
                                itemProps: getItemProps({ item: suggestion.label }),
                                highlightedIndex,
                                selectedItem,
                              }),
                            )}
                          </Paper>
                        ) : null}
                      </div>
                    )}
                  </Downshift>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      }
    }

    const getStepContent = (step) => {
      const catalogs = this.state.catalogs;

      switch (step) {
        case 0:
          return (
            <div>
              <section className={classes.bodyForm}>
                {/*Temporary Submit*/}

                <div>
                  <ExpansionPanel defaultExpanded expanded={expanded === 'panel1'} onChange={this.handleChangeExpand('panel1')}>
                    {/*General Data*/}
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>Datos Generales</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        {/*Company/Provider, Required Select*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            {/*New Select 2024*/}
                                <SelectValidatorCustom
                                  label="Empresa/Proveedor*"
                                  name ="new_empresa_proveedor"
                                  isClearable={false}
                                  defaultValue={this.state.new_empresa_proveedor}
                                  classes={classes}
                                  styles={selectStyles}
                                  options={this.state.companies}
                                  value={this.state.new_empresa_proveedor}
                                  onChange={(e) => {
                                    const customEvent = {
                                      target: {
                                        name: "new_empresa_proveedor",
                                        value: e.value
                                      }
                                    };
                                    this.handleChange(customEvent);
                                  }}
                                  placeholder=""
                                  validators={['required']}
                                  errorMessages={['Campo requerido']}
                                />                     
                        </Grid>

                        {/*Title, required*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          {/*AHDA 30/05/2019: Changed validation*/}
                          {/*AHDA 20/06/2019: Added empty space validation*/}
                          {/*AHDA 26/06/2019: Added disabled*/}
                          <TextValidator
                            label="Título de la obra*"
                            placeholder="Ej. Senda Prohibida"
                            id="new_title"
                            name="new_title"
                            key={1}
                            value={this.state.new_title}
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            validators={['required', 'isSpaceEmpty']}
                            errorMessages={['Campo requerido', 'Campo requerido']}
                            InputLabelProps={{ shrink: true }}
                            InputProps={
                              {
                                inputProps: { maxLength: 100 }
                              }
                            }
                          // disabled={this.state.isCompleteEdit && this.state.disabledCode ? true : false}
                          />
                        </Grid>

                        {/*Destiny */}
                        {/* <Grid item lg={4} md={6} sm={12} xs={12}>
                            <FormControl className={classNames(classes.formControl, classes.w100)}>
                              <SelectValidator
                                label="Destino*"
                                value={this.state.new_clasification}
                                onChange={this.handleChange}
                                name="new_clasification"
                                id="new_clasification"
                                validators={['required']}
                                errorMessages={['Campo requerido']}
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                                displayEmpty
                              >
                                <MenuItem value={'produccion'}>Producción</MenuItem>
                                <MenuItem value={'ventas'}>Ventas</MenuItem>
                              </SelectValidator>
                            </FormControl>
                          </Grid> */}

                        {/*Source, required*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <SelectValidator
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 4).cat_name}*`}
                              value={this.state.new_source}
                              onChange={this.handleChange}
                              name="new_source"
                              id="new_source"
                              validators={['required']}
                              errorMessages={['Campo requerido']}
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              displayEmpty
                            >
                              {/*AHDA 22/04/2019: Empty catalogue*/}
                              {
                                this.state.sources.length < 0 ?
                                  <MenuItem value={undefined} disabled>No hay fuentes disponibles</MenuItem> :
                                  this.state.sources.map((source, idx) => (
                                    <MenuItem value={source.value} key={`source_${idx}`}>{source.data}</MenuItem>
                                  ))
                              }
                            </SelectValidator>
                          </FormControl>
                        </Grid>

                        {/*Work Type, required*/}
                        {/*AHDA 26/06/2019: Added disabled*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <SelectValidator
                              label="Tipo de obra*"
                              value={this.state.new_type}
                              onChange={this.handleChange}
                              name="new_type"
                              id="new_type"
                              validators={['required']}
                              errorMessages={['Campo requerido']}
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              displayEmpty
                            // disabled={this.state.isCompleteEdit && this.state.disabledCode ? true : false}
                            >
                              <MenuItem value={'primigenia'}>Primigenia</MenuItem>
                              <MenuItem value={'adaptacion'}>Adaptación</MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>

                        {/*Version Number, required only for adaptation type*/}
                        {
                          this.state.new_type === "adaptacion" ?
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                              {/*AHDA 26/06/2019: Added adaptationNumber validation*/}
                              <FormControl className={classNames(classes.formControl, classes.w100)}>
                                <SelectValidator
                                  label="Número de adaptación*"
                                  value={this.state.new_version_number}
                                  onChange={this.handleChange}
                                  name="new_version_number"
                                  id="new_version_number"
                                  validators={['required', 'adaptationNumber']}
                                  errorMessages={['Campo requerido', 'Campo requerido']}
                                  className={classes.selectEmpty}
                                  InputLabelProps={{ shrink: true }}
                                  displayEmpty
                                >
                                  <MenuItem value={11}>11</MenuItem>
                                  <MenuItem value={12}>12</MenuItem>
                                  <MenuItem value={13}>13</MenuItem>
                                  <MenuItem value={14}>14</MenuItem>
                                  <MenuItem value={15}>15</MenuItem>
                                  <MenuItem value={16}>16</MenuItem>
                                  <MenuItem value={17}>17</MenuItem>
                                  <MenuItem value={18}>18</MenuItem>
                                  <MenuItem value={19}>19</MenuItem>
                                </SelectValidator>
                              </FormControl>
                            </Grid> : null
                        }

                        {/*Produced, required*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <SelectValidator
                              label="Producida*"
                              value={this.state.new_produced}
                              onChange={this.handleChange}
                              name="new_produced"
                              id="new_produced"
                              validators={['required']}
                              errorMessages={['Campo requerido']}
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              displayEmpty
                            >
                              <MenuItem value={1}>Sí</MenuItem>
                              <MenuItem value={0}>No</MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <TextField
                            label="Título en inglés"
                            placeholder="Ej. Forbidden Path"
                            id="new_english_title"
                            name="new_english_title"
                            value={this.state.new_english_title}
                            type="text"
                            autoComplete="off"
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            inputProps={
                              { maxLength: 100 }
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <TextField
                            label="Título en otro idioma"
                            placeholder="Ej. Forbidden Path"
                            id="new_title_other"
                            name="new_title_other"
                            value={this.state.new_title_other}
                            type="text"
                            autoComplete="off"
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            inputProps={
                              { maxLength: 100 }
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        {/*Original Work Title, required only for adaptations*/}
                        {
                          this.state.new_type === "adaptacion" ?
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                              {/*AHDA 20/06/2019: Added empty space validation*/}


                              {/* JCMY 20/06/2019 */}
                              <Downshift selectedItem={this.state.new_title_originalVersion} onStateChange={this.handleStateChange}>
                                {({
                                  getInputProps,
                                  getItemProps,
                                  isOpen,
                                  inputValue,
                                  selectedItem,
                                  highlightedIndex
                                }) => (
                                  <div className={classes.formControl}>
                                    {renderInput({
                                      fullWidth: true,
                                      classes,
                                      // CH 26/06/2019 Se acomoda label
                                      label: "Título de la obra primigenia*",
                                      InputLabelProps: { shrink: true },
                                      InputProps: getInputProps({
                                        placeholder: "Ej. Senda Prohibida",
                                        id: 'new_title_originalVersion',
                                        name: 'new_title_originalVersion',
                                      }),
                                    })}
                                    {isOpen ? (
                                      <Paper className={classes.paper} square>
                                        {getSuggestions(inputValue, this.state.worksCatalog).map((suggestion, index) =>
                                          renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion.label }),
                                            highlightedIndex,
                                            selectedItem,
                                          }),
                                        )}
                                      </Paper>
                                    ) : null}
                                  </div>
                                )}
                              </Downshift>




                              {/* <TextValidator
                                label="Título de la obra primigenia*"
                                placeholder="Ej. Senda Prohibida"
                                id="new_title_originalVersion"
                                name="new_title_originalVersion"
                                key={2}
                                value={this.state.new_title_originalVersion}
                                className={classNames(classes.field, classes.w100)}
                                onChange={this.handleChange}
                                validators={['required', 'isSpaceEmpty']}
                                errorMessages={['Campo requerido', 'Campo requerido']}
                                InputProps={
                                  {
                                    inputProps: { maxLength: 100 }
                                  }
                                }
                                InputLabelProps={{ shrink: true }}
                              /> */}




                            </Grid> : null
                        }

                        {/*Country, required*/}
                        {/*AHDA 26/06/2019: Added disabled*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            {/*New Select 2024*/}

                            <SelectValidatorCustom
                              label="País de origen*"
                              name ="new_country_origin"
                              isClearable={false}
                              defaultValue={this.state.new_country_origin}
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.countries}
                              value={this.state.new_country_origin}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_country_origin",
                                    value: e.value
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                              validators={['required']}
                              errorMessages={['Campo requerido']}
                            /> 
                        </Grid>

                        {/*Chapter number*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          {/*AHDA 20/06/2019: Added empty space validation*/}
                          <TextValidator
                            label="Libretos*"
                            placeholder="Ej. 170"
                            id="new_chapter_number"
                            name="new_chapter_number"
                            key={3}
                            value={this.state.new_chapter_number}
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            validators={['required', 'isSpaceEmpty', 'isPositive', 'minNumber:1']}
                            errorMessages={['Campo requerido', 'Campo requerido', 'Sólo números positivos', 'Debe tener más de cero']}
                            InputProps={
                              {
                                inputProps: { maxLength: 5 }
                              }
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        {/*Chapter duration*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <SelectValidator
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 3).cat_name}*`}
                              value={this.state.new_chap_duration}
                              onChange={this.handleChange}
                              name="new_chap_duration"
                              id="new_chap_duration"
                              validators={['required']}
                              errorMessages={['Campo requerido']}
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              displayEmpty
                            >
                              {/*AHDA 22/04/2019: Empty catalogue*/}
                              {
                                this.state.durations.length < 0 ?
                                  <MenuItem value={undefined} disabled>No hay duraciones disponibles</MenuItem> :
                                  this.state.durations.map((duration, idx) => (
                                    <MenuItem value={duration.value} key={`duration_${idx}`}>{duration.data}</MenuItem>
                                  ))
                              }
                            </SelectValidator>
                          </FormControl>
                        </Grid>

                        {/*File number, generated field*/}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          {/*AHDA 20/06/2019: Added empty space validation*/}
                          <TextValidator
                            label="Folio de la obra*"
                            placeholder="Ej. ___10000.00"
                            id="new_fileNumber"
                            name="new_fileNumber"
                            value={this.getFileNumber()}
                            type="text"
                            autoComplete="off"
                            disabled={true}
                            className={classNames(classes.field, classes.w100)}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <SelectValidator
                              label="Producción*"
                              value={this.state.new_production}
                              onChange={this.handleChange}
                              name="new_production"
                              id="new_production"
                              // validators={}
                              // errorMessages={}
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              displayEmpty
                            >
                              <MenuItem value={"Producción Televisa"}>Producción Televisa</MenuItem>
                              <MenuItem value={"Producción Extranjera"}>Producción Extranjera</MenuItem>
                              <MenuItem value={"No producida"}>No producida</MenuItem>
                              <MenuItem value={"Inédita"}>Inédita</MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>

                        {/*Categories*/}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {/* <InputLabel htmlFor="proCategorias">Categorias</InputLabel> */}
                          {/*AHDA 31/05/2019: changed newChipcodes*/}
                          <ChipInput
                            label="Etiquetas"
                            placeholder="Ej. amor, drama, cdmx"
                            value={this.state.new_tags}
                            onAdd={(chip) => this.handleAddChip(chip)}
                            onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                            newChipKeyCodes={[13, 9]}
                            className={classNames(classes.field, classes.w100)}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {/*Auhors and Adapters*/}
                  <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChangeExpand('panel2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>{`${catalogs.find(({ id_catalogue }) => id_catalogue === 17).cat_name}`}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className={classes.root}>

                        {/*Author Table*/}
                        {/*CH 04/06/2019: Se agrega ellipsis*/}
                        <Paper className={classes.rootTable}>
                          {/*AHDA 04/06/2019: Added new work validation*/}
                          <Header
                            title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 17).cat_name}`}
                            ellipsis={true}
                            tooltip="Agregar Autor"
                            textBtn="Nuevo"
                            isComplete={false}
                            openForm={() => this.handledOpenFormAuthor(undefined)}
                            canAdd={!this.state.isCompleteEdit ? true : canEdit}
                            showSearch={false}
                          />
                          {/*AHDA 21/05/2019: Added showSearch*/}
                          {
                            this.state.data.length > 0 ?
                              <TableCatalogue
                                headers={this.state.headers}
                                data={this.state.data}
                                openForm={this.handledOpenFormAuthor}
                                canEdit={canEdit}
                                canDelete={canEdit}
                                deleteItem={this.handleDeleteFormAuthors} /> : null
                          }
                        </Paper>

                        {/*Add new Author*/}
                        {/*AHDA 06/06/2019: Changed Title*/}
                        <AddCatalogue
                          openForm={this.state.openFormAuthor}
                          closeForm={this.handledCloseForm}
                          classes={classes}
                          submit={this.handleSubmitFormAuthor}
                          clearForm={() => this.handleClear('authors')}
                          title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 17).cat_name}`}
                          withForm={false}
                        >
                          <div>
                            <Grid
                              container
                              spacing={24}>
                              {/*Author Name, required*/}
                              <Grid item xs={12} sm={12} md={12}>
                              <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errornew_author_name}>
                                  <SelectNew
                                    label="Nombre"
                                    name ="new_author_name"
                                    options={this.state.authors}
                                    value={this.state.new_author_name}
                                    onChange={(e) => {
                                      const customEvent = {
                                        target: {
                                          name: "new_author_name",
                                          value: e.value
                                        }
                                      };
                                      this.handleChange(customEvent);
                                    }}
                                    placeholder=""

                                  />
                                  {this.state.errornew_author_name ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                                </FormControl>
                              </Grid>
                              {/*Author participation*/}
                              <Grid item xs={12} sm={12} md={12}>
                                <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errornew_author_rol}>
                                  <InputLabel shrink htmlFor="new_author_rol">Participación</InputLabel>
                                  <Select
                                    value={this.state.new_author_rol}
                                    onChange={this.handleChange}
                                    displayEmpty
                                    name="new_author_rol"
                                    id="new_author_rol"
                                    onBlur={this.handleBlur}
                                    className={classes.selectEmpty}
                                    InputLabelProps={{ shrink: true }}
                                  >
                                    <MenuItem value={'Autor'}>Autor</MenuItem>
                                    <MenuItem value={'Adaptador'}>Adaptador</MenuItem>
                                  </Select>
                                  {this.state.errornew_author_rol ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                        </AddCatalogue>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {/*Literary Information*/}
                  <ExpansionPanel defaultExpanded expanded={expanded === 'panel3'} onChange={this.handleChangeExpand('panel3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>Información Literaria</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid
                        container
                        spacing={16}>
                        {/*Format, required*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            
                            {/*New Select 2024*/}
                            <SelectValidatorCustom
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 8).cat_name}*`}
                              name ="new_format"
                              isClearable={false}
                              defaultValue={this.state.new_format}
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.formats}
                              value={this.state.new_format}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_format",
                                    value: e.value
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                              validators={['required']}
                              errorMessages={['Campo requerido']}
                            />
                        </Grid>

                        {/*Platform*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>                       
                            <SelectNew 
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 9).cat_name}*`}
                              name="new_platform"
                              isClearable
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.platforms}
                              value={this.state.new_platform}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_platform",
                                    value: e ? e.value : null
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>

                        {/*Audience*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            {/*New Select 2024*/}
                            <SelectNew
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 10).cat_name}*`}
                              name ="new_audience"
                              isClearable
                              defaultValue={this.state.new_audience}
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.audiences}
                              value={this.state.new_audience}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_audience",
                                    value: e ? e.value : null
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>

                        {/*Schedule*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <InputLabel shrink htmlFor="new_schedule">Horario aire</InputLabel>
                            <Select
                              value={this.state.new_schedule}
                              onChange={this.handleChange}
                              displayEmpty
                              name="new_schedule"
                              id="new_schedule"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                            >
                              {/* JCMY 23/07/2019 incidencia 1088 */}
                              <MenuItem value={undefined} key={`schedule_`}> </MenuItem>

                              {/*AHDA 22/04/2019: Empty catalogue*/}
                              {
                                schedule.map((sch, idx) =>
                                  (<MenuItem value={sch} key={`schedule_${idx}`}>{sch}</MenuItem>)
                                )
                              }
                            </Select>
                          </FormControl>
                        </Grid>

                        {/*Genre, required*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            {/*New Select 2024*/}
                            <SelectValidatorCustom
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 5).cat_name}*`}
                              name ="new_genre"
                              defaultValue={this.state.new_genre}
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.genres}
                              value={this.state.new_genre}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_genre",
                                    value: e.value
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                              validators={['required']}
                              errorMessages={['Campo requerido']}
                            />
                        </Grid>

                        {/*Subgenre*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            {/*New Select 2024*/}
                            <SelectNew
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 6).cat_name}*`}
                              name ="new_subgenre"
                              isClearable
                              defaultValue={this.state.new_subgenre}
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.subgenres}
                              value={this.state.new_subgenre}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_subgenre",
                                    value: e ? e.value : null
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>

                        {/*Theme*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <SelectNew
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 7).cat_name}*`}
                              name ="new_theme"
                              isClearable
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.themes}
                              value={this.state.new_theme}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_theme",
                                    value: e ? e.value : null
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>

                        {/*Motivation*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            {/*New Select 2024*/}
                            <SelectNew
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 11).cat_name}*`}
                              name ="new_motivation"
                              isClearable
                              defaultValue={this.state.new_motivation}
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.motivations}
                              value={this.state.new_motivation}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_motivation",
                                    value: e ? e.value : null
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>

                        {/*Temporality*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                            <SelectNew
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 12).cat_name}*`}
                              name ="new_temporality"
                              isClearable
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.temporalities}
                              value={this.state.new_temporality}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_temporality",
                                    value: e ? e.value : null
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>

                        {/*Environment*/}
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                            
                            {/*New Select 2024*/}
                            <SelectValidatorCustom
                              label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 13).cat_name}*`}
                              name ="new_environment"
                              isClearable
                              classes={classes}
                              styles={selectStyles}
                              options={this.state.environments}
                              value={this.state.new_environment}
                              onChange={(e) => {
                                const customEvent = {
                                  target: {
                                    name: "new_environment",
                                    value: e ? e.value : null
                                  }
                                };
                                this.handleChange(customEvent);
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)}>
                          <InputLabel shrink htmlFor="suggested_schedules">{`${catalogs.find(({ id_catalogue }) => id_catalogue === 22).cat_name}*`}</InputLabel>
                            <Select
                              multiple
                              value={this.state.new_suggested_schedules}
                              onChange={(e) => e.target.value.length < 3 ? this.handleChange(e) : null}
                              displayEmpty
                              name="new_suggested_schedules"
                              id="new_suggested_schedules"
                              input={<Input id="suggested_schedules-multiple" />}
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              renderValue={selected => (
                                <div className={classes.chips}>
                                  {selected.map((value, idx) => {
                                    let auxValue = this.state.schedules.find(p => p.value === value);
                                    return <Chip key={`chip_horario_${idx}`} label={auxValue ? auxValue.data : '-'} className={classes.chip} />
                                  })}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {
                                this.state.schedules.length < 0 ?
                                  <MenuItem value={undefined} disabled>No hay horarios disponibles</MenuItem> :
                                  this.state.schedules.map((e, idx) => (
                                    <MenuItem value={e.value} key={`horario${idx}`}>{e.data}</MenuItem>
                                  ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {/*Production, show only if Production is selected*/}
                  {
                    this.state.new_produced === 1 ?
                      <ExpansionPanel expanded={expanded === 'panel4'} onChange={this.handleChangeExpand('panel4')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                          <Typography className={classes.headingExpand}>Productor</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container spacing={16}>
                            {/*Producers, required*/}
                            <Grid item lg={12} md={12} sm={12} xs={12}>

                              <SelectValidatorCustom
                                  label={`${catalogs.find(({ id_catalogue }) => id_catalogue === 16).cat_name}*`}
                                  name="new_producers"
                                  isClearable
                                  classes={classes}
                                  styles={selectStyles}
                                  options={this.state.producers}
                                  value={this.state.new_producers}
                                  onChange={(e) => {
                                    const customEvent = {
                                      target: {
                                        name: "new_producers",
                                        value: e.map(x => x.value),
                                      }
                                    }
                                    this.handleChange(customEvent)
                                  }}
                                  isMulti
                                  validators={['required']}
                                  errorMessages={['Campo requerido']}
                                />
                            </Grid>

                            {/*Production Year, required*/}
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                              {/*AHDA 20/06/2019: Added empty space validation*/}
                              <TextValidator
                                label="Año de Producción*"
                                placeholder="Ej. 2018"
                                id="new_productionYear"
                                name="new_productionYear"
                                key={4}
                                value={this.state.new_productionYear}
                                className={classNames(classes.field, classes.w100)}
                                onChange={this.handleChange}
                                validators={['required', 'isSpaceEmpty', 'isPositive', 'minNumber:1000']}
                                errorMessages={['Campo requerido', 'Campo requerido', 'Año inválido', 'Año inválido']}
                                InputProps={
                                  {
                                    inputProps: { maxLength: 4 }
                                  }
                                }
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>

                            {/*AiredChapters, required*/}
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                              <InputLabel htmlFor="new_airedChapters"></InputLabel>
                              {/*AHDA 03/05/2019: Added validations*/}
                              {/*AHDA 20/06/2019: Added empty space validation*/}
                              <TextValidator
                                label="Capítulos al Aire*"
                                placeholder="Ej. 95"
                                id="new_airedChapters"
                                name="new_airedChapters"
                                key={5}
                                value={this.state.new_airedChapters}
                                className={classNames(classes.field, classes.w100)}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                                validators={['required', 'isSpaceEmpty', 'isPositive', 'minNumber:1']}
                                errorMessages={['Campo requerido', 'Campo requerido', 'Sólo números positivos', 'Debe tener más de cero']}
                                InputProps={
                                  {
                                    inputProps: { maxLength: 5 }
                                  }
                                }
                              />
                            </Grid>

                            {/*Duration Aired*/}
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                              <FormControl className={classNames(classes.formControl, classes.w100)}>
                                <SelectValidator
                                  label="Duración*"
                                  value={this.state.new_aired_duration}
                                  onChange={this.handleChange}
                                  name="new_aired_duration"
                                  id="new_aired_duration"
                                  validators={['required']}
                                  errorMessages={['Campo requerido']}
                                  className={classes.selectEmpty}
                                  InputLabelProps={{ shrink: true }}
                                  displayEmpty
                                >
                                  {/*AHDA 22/04/2019: Empty catalogue*/}
                                  {
                                    this.state.durations.length < 0 ?
                                      <MenuItem value={undefined} disabled>No hay duraciones disponibles</MenuItem> :
                                      this.state.durations.map((duration, idx) => (
                                        <MenuItem value={duration.value} key={`durationProd_${idx}`}>{duration.data}</MenuItem>
                                      ))
                                  }
                                </SelectValidator>
                              </FormControl>

                            </Grid>

                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel> : null
                  }

                  {/*Other versions, only visible for Original Produced, Adaptation*/}
                  {getVersionsExpandablePanel()}

                  {getMorePrimigeniasForAdaptation()}
                </div>
              </section>
            </div>
          );
        case 1:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div>
                  {/*Logline*/}
                  <ExpansionPanel expanded={expanded === 'panel2_1'} onChange={this.handleChangeExpand('panel2_1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Logline*</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        {/*Logline*/}
                        <Grid item md={12} sm={12} xs={12}>
                          {/* <Editor
                            editorState={this.state.editorStateLogline}
                            // disabled={!this.state.chkEditParameter}
                            editorClassName={EditorStyle.TextEditor}
                            toolbarClassName={EditorStyle.ToolbarEditor}
                            onEditorStateChange={this.onEditorStateChange}
                            // handlePastedText={this.handlePastedText}
                          /> */}
                          {/*AHDA 20/06/2019: Added empty space validation*/}
                          <TextValidator
                            placeholder="Escribe aquí"
                            id="new_logline"
                            name="new_logline"
                            multiline
                            rows="7"
                            disabled={!canViewSinopsis}
                            value={this.state.new_logline}
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            validators={['required', 'isSpaceEmpty']}
                            errorMessages={['Campo requerido', 'Campo requerido']}
                            InputProps={
                              {
                                inputProps: {
                                  maxLength: 1000
                                }
                              }
                            }
                          />
                          <div className={classes.noteSpace}>
                            <Typography><small>Máximo 1,000 caracteres</small></Typography>
                            <Typography><small><strong>{this.state.new_logline ? this.state.new_logline.length : 0}</strong> de 1,000</small></Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {/*Short Synopsis*/}
                  <ExpansionPanel expanded={expanded === 'panel2_2'} onChange={this.handleChangeExpand('panel2_2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Sinopsis corta*</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={12} sm={12} xs={12}>
                          {/*AHDA 20/06/2019: Added empty space validation*/}
                          {/* <TextValidator
                            placeholder="Escribe una sinopsis corta"
                            id="new_shortSynopsis"
                            name="new_shortSynopsis"
                            multiline
                            rows="7"
                            value={this.state.new_shortSynopsis}
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            validators={['required', 'isSpaceEmpty']}
                            errorMessages={['Campo requerido', 'Campo requerido']}
                            InputProps={
                              {
                                inputProps: {
                                  maxLength: 15000
                                }
                              }
                            }
                          /> */}
                          <Editor
                            editorState={this.state.editorStateSipCor}
                            readOnly={!canViewSinopsis}
                            editorClassName={EditorStyle.TextEditor}
                            toolbarClassName={EditorStyle.ToolbarEditor}
                            onEditorStateChange={this.onEditorStateChangeSipCor}
                          // handlePastedText={this.handlePastedText}
                          />
                          {/* <Editor
                            style={{ height: '320px' }}
                            value={this.state.editorSipnosisCorta}
                            onTextChange={ e => this.onEditorStateChangeSipCor(e.htmlValue)}
                          /> */}
                          <div className={classes.noteSpace}>
                            <Typography><small>Máximo 15,000 caracteres</small></Typography>
                            <Typography><small><strong>{this.state.SipCorLength ? this.state.SipCorLength : 0}</strong> de 15,000</small></Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {/*Long Synopsis*/}
                  <ExpansionPanel expanded={expanded === 'panel2_3'} onChange={this.handleChangeExpand('panel2_3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Sinopsis Larga*</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Editor
                            editorState={this.state.editorStateSipLar}
                            readOnly={!canViewSinopsis}
                            editorClassName={EditorStyle.TextEditor}
                            toolbarClassName={EditorStyle.ToolbarEditor}
                            onEditorStateChange={this.onEditorStateChangeSipLar}
                          // handlePastedText={this.handlePastedText}
                          />
                          {/* <Editor
                            style={{ height: '320px' }}
                            value={this.state.editorSipnosisLarga}
                            onTextChange={ e => this.onEditorStateChangeSipLar(e.htmlValue)}
                          /> */}
                          {/*AHDA 20/06/2019: Added empty space validation*/}
                          {/* <TextValidator
                            placeholder="Escribe una sinopsis larga"
                            id="new_long_synopsis"
                            name="new_long_synopsis"
                            multiline
                            rows="7"
                            value={this.state.new_long_synopsis}
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            validators={['required', 'isSpaceEmpty']}
                            errorMessages={['Campo requerido', 'Campo requerido']}
                          /> */}
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </section>
            </div>
          );
        case 2:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div>
                  {/*Characters*/}
                  <ExpansionPanel expanded={expanded === 'panel3_1'} onChange={this.handleChangeExpand('panel3_1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Personajes</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          {/*New Character*/}
                          <Grid item container direction="row" justify="flex-end" alignItems="flex-start" className={classes.pb3}>
                            {/* isanchez 07.05.2019 add permissions for edit work */}
                            {/*AHDA 04/06/2019: Added new work validation*/}
                            {
                              !this.state.isCompleteEdit || canEdit ?
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.button}
                                  disabled={!canViewPersonaje}
                                  onClick={() => this.handledOpenFormCharacters(undefined)}>
                                  <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> Nuevo Personaje
                                </Button>
                                : ''
                            }
                          </Grid>
                          {/*Characters*/}
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={24}>
                            {
                              /*this.state.new_characters.map((character, idx) => (
                                <CharacterPaper
                                  character={character}
                                  onEdit={this.handledOpenFormCharacters}
                                  onDelete={this.handleDeleteCharacters} key={`character_${idx}`} />
                              ))*/
                            }
                            <Grid item md={12} sm={12} xs={12}>
                              <ReactDragList
                                dataSource={this.state.new_characters}
                                rowKey="id"
                                row={(record, index) => (
                                  <CharacterPaper
                                    character={record}
                                    onEdit={this.handledOpenFormCharacters}
                                    canEdit={canEdit}
                                    canDelete={canEdit}
                                    canViewPersonaje={canViewPersonaje}
                                    onDelete={this.handleDeleteCharacters} key={`character_${index}`} />
                                )}
                                handles={false}
                                onUpdate={this.handleUpdateCharacters}
                              />
                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>

                    {/*Add new character form*/}
                    {/*AHDA 06/06/2019: Changed Title*/}
                    <AddCatalogue
                      openForm={this.state.openFormCharacters}
                      closeForm={this.handledCloseForm}
                      classes={classes}
                      submit={this.handleSubmitCharacters}
                      clearForm={() => this.handleClear('characters')}
                      title="Personaje"
                      withForm={false}>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} md={8}>
                          <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errornew_characterName}>
                            <TextField
                              error={this.state.errornew_characterName}
                              label="Nombre completo*"
                              placeholder="Ej. Federico García"
                              id="new_characterName"
                              name="new_characterName"
                              value={this.state.new_characterName}
                              type="text"
                              autoComplete="off"
                              className={classes.field}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              InputLabelProps={{ shrink: true }}
                            />
                            {this.state.errornew_characterName ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                          </FormControl>

                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <FormControl className={classNames(classes.field, classes.w100)}>
                            <TextField
                              label="Edad"
                              placeholder="Ej. 45 años"
                              id="new_characterAge"
                              name="new_characterAge"
                              value={this.state.new_characterAge}
                              autoComplete="off"
                              type="text"
                              className={classes.field}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              inputProps={
                                { maxLength: 50 }
                              }
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          <FormControl className={classNames(classes.formControl, classes.w100)} error={this.state.errornew_characterPsychology}>
                            {/* GEO 11/04/2019
                            Se cambia texto de placeholder */}
                            {/*AHDA 06(06/2019: Changed maxLength to 1500*/}
                            <TextField
                              error={this.state.errornew_characterPsychology}
                              label="Psicología del personaje"
                              placeholder="Ej. Viudo, padre de siete hijos. Es un hombre estricto y formal. Está metido en su trabajo, porque busca darle lo mejor a su familia, sin darse cuenta que tal vez lo que sus hijos necesitan es su cariño y atención. La llegada de Ana tendrá un gran impacto en él, ya que despertará su pasión por la vida."
                              id="new_characterPsychology"
                              name="new_characterPsychology"
                              multiline
                              rows="5"
                              className={classes.field}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              value={this.state.new_characterPsychology}
                              inputProps={
                                { maxLength: 1500 }
                              }
                              InputLabelProps={{ shrink: true }}
                            />
                            {this.state.errornew_characterPsychology ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </AddCatalogue>
                  </ExpansionPanel>

                  {/*Relationship diagram*/}
                  <ExpansionPanel expanded={expanded === 'panel3_2'} onChange={this.handleChangeExpand('panel3_2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Diagrama de Relaciones</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        {
                          this.state.new_CharacterDiagram ?
                            <Grid item xs={12}>
                              <List dense={dense}>
                                <ListItem>
                                  <ListItemText
                                    primary={getFileTitle(this.state.new_CharacterDiagram.ruta)}
                                    secondary={secondary ? 'Secondary text' : null}
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton aria-label="Delete" onClick={() => this.handleDeleteFile(this.state.new_CharacterDiagram, "diagrama relaciones", "new_CharacterDiagram")}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </List>
                            </Grid> :
                            <Grid item xs={12}>
                              {/*AHDA
                             29/04/2019: Added param, temporal solution*/}
                              <MaterialDropZone
                                onDrop={(files) => this.onDrop(files, "pdf")}
                                onRemove={this.handleRemove}
                                files={filesDiagram}
                                showPreviews
                                acceptedFiles={["application/pdf"]}
                                maxSize={5000000}
                                filesLimit={1}
                                canAdd={canEdit}
                                text="Arrastra tu archivo o da click aquí"
                              />
                            </Grid>
                        }
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </section>
            </div>
          );

        case 3:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div>
                  {/*Evaluation*/}
                  <ExpansionPanel expanded={expanded === 'panel4_1'} onChange={this.handleChangeExpand('panel4_1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Evaluación</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={12} sm={12} xs={12}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              keyboard={false}
                              label={"Fecha en la que se realizó el dictamen"}
                              className={classes.dateControl}
                              format="DD/MM/YYYY"
                              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                              value={this.state.new_ev_date}
                              onChange={(e) => this.setState({ new_ev_date: e })}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              disableFuture={true}
                              clearable={true}
                              cancelLabel={lang.common.cancel}
                              clearLabel={lang.common.clear}
                              placeholder="DD/MM/AAAA"
                              InputLabelProps={{ shrink: true }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        {/*Comments*/}
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            label="Comentarios"
                            placeholder="Escriba sus comentarios"
                            id="new_ev_comments"
                            name="new_ev_comments"
                            multiline
                            rows="7"
                            className={classNames(classes.field, classes.w100)}
                            onChange={this.handleChange}
                            value={this.state.new_ev_comments}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        {/*Evaluation files*/}
                        <Grid item xs={12}>
                          {/*AHDA 29/04/2019: Added param onDrop, temporal solution*/}
                          <InputLabel htmlFor="proShare">Dictamen de Evaluación</InputLabel>
                          {this.state.new_EvaluationFile &&
                            this.state.new_EvaluationFile.map((item, key) => {
                              item.key = key;
                              if (!item.ruta)
                                item.ruta = "";
                              return <Grid item xs={12}>
                                <List dense={dense}>
                                  <ListItem>
                                    <ListItemText
                                      primary={getFileTitle(item.ruta)}
                                      secondary={`${moment(item.fecha_evaluacion).format('L')} - ${item.comentarios || ""}`}
                                    />
                                    <ListItemSecondaryAction>
                                      <IconButton aria-label="Delete" onClick={() => this.handleDeleteFile(item, "evaluacion", "new_EvaluationFile")}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </List>
                              </Grid>
                            })
                          }
                          {
                            canEvaluate && <MaterialDropZone
                              files={filesEvaluation}
                              onDrop={(files) => this.onDrop(files, "word", true)}
                              onRemove={this.handleRemove}
                              showPreviews
                              maxSize={5000000}
                              filesLimit={1}
                              acceptedFiles={this.state.acceptedFilesEval}
                              text="Arrastra tu archivo o da click aquí"
                            />
                          }
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </section>
            </div>
          );
          {/*Add new Material*/ }
        case 4:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div>
                  {
                    (canEdit || !this.state.isCompleteEdit) ?
                      <Grid item container direction="row" justify="flex-end" alignItems="flex-start" className="pb3">
                        <Button disabled={!canAddMaterial} variant="contained" size="small" color="primary" className={classes.button} onClick={() => this.handledOpenFormMaterial(undefined)}>
                          <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> {lang.adminWork.addMaterial}
                        </Button>
                      </Grid> : ''
                  }

                  {/*Scripts*/}
                  <ExpansionPanel expanded={expanded === 'panel5_1'} onChange={this.handleChangeExpand('panel5_1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Libretos</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          {
                            this.state.new_mScripts.length <= 0 ?
                              <Typography className={classes.info}>{lang.adminWork.noDataMaterials}</Typography> :
                              null
                          }

                          {
                            this.state.new_mScripts.map((script, idx) => {
                              // isanchez 10.08.2019 no mostrar si no hay libretos
                              if (script.scripts.length > 0) {
                                return <ScriptDetail
                                  key={`script_${idx}`}
                                  script={script}
                                  onDelete={this.handleDeleteMaterial}
                                  onEdit={this.handleOpenEdit}
                                  onChangeMaterial={this.handleChangeMaterialMissing}
                                />
                              }
                            })
                          }
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {/*Videos*/}
                  <ExpansionPanel expanded={expanded === 'panel5_2'} onChange={this.handleChangeExpand('panel5_2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Videos</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.flexContainer}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        spacing={24}>
                        {
                          this.state.new_mVideos.length <= 0 ?
                            <Grid item xs={12}>
                              <Typography className={classes.info}>{lang.adminWork.noDataMaterials}</Typography>
                            </Grid> :
                            this.state.new_mVideos.map((video, index) => {
                              return (<Grid item xs={12} sm={12} md={12} key={`video_${index}`}>
                                <VideoPaper
                                  video={video}
                                  onDelete={this.handleDeleteMaterial}
                                  onEdit={this.handleOpenEdit}
                                  catalogName={`${catalogs.find(({ id_catalogue }) => id_catalogue === 3).cat_name}`}
                                />
                              </Grid>);
                            })
                        }
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {/*Imágenes*/}
                  <ExpansionPanel expanded={expanded === 'panel5_3'} onChange={this.handleChangeExpand('panel5_3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>Imágenes</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        this.state.new_mImages.length <= 0 ?
                          <Grid container item xs={12} alignItems="center" spacing={24}>
                            <Typography className={classes.info}>{lang.adminWork.noDataMaterials}</Typography>
                          </Grid> : (
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center"
                              spacing={16}>
                              <Grid item xs={12}>
                                <List dense={dense} component="nav" >
                                  {
                                    this.state.new_mImages.map((img, idx) => (
                                      <ListItem key={idx} className={classes.listItem}>
                                        <ListItemAvatar>
                                          {
                                            !img.ruta ?
                                              <img width={200} src={img.file.preview} alt='titulo' /> :
                                              <div><S3Image width={200} imgKey={img.ruta} /></div>
                                          }
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={`${lang.adminWork.material_description}: ${img.description || ''}`}
                                          secondary={`${lang.adminWork.material_amount}: ${img.amount || ''}`}
                                        />
                                        <ListItemSecondaryAction>
                                          {img.new && <Tooltip title="Editar más información"><IconButton
                                            className={classes.button}
                                            aria-label={lang.common.edit}
                                            onClick={() => this.handleOpenEdit(img, "img", "new_mImages")}
                                          >
                                            <Edit className={classes.iconxs} />
                                          </IconButton></Tooltip>}
                                          <Tooltip title="Eliminar">
                                            <IconButton onClick={() => this.handleDeleteMaterial([img], "img", "new_mImages")}>
                                              <DeleteIcon className={classes.iconxs} />
                                            </IconButton>
                                          </Tooltip>
                                        </ListItemSecondaryAction>
                                        <Divider />
                                      </ListItem>
                                    ))
                                  }
                                </List>
                              </Grid>
                            </Grid>
                          )

                      }
                      {/* {
                        this.state.new_mImages.length <= 0 ?
                        <Grid container item xs={12} alignItems="center" spacing={24}>
                          <Typography className={classes.info}>No se ha cargada ningún material</Typography>
                        </Grid> : 
                        <ImageCarousel imgData={this.state.new_mImages}/>
                      } */}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {/*Other Materials*/}
                  <ExpansionPanel expanded={expanded === 'panel5_4'} onChange={this.handleChangeExpand('panel5_4')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography className={classes.headingExpand}>Otros Materiales</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          {
                            this.state.new_mOthers.length <= 0 ?
                              <Typography className={classes.info}>{lang.adminWork.noDataMaterials}</Typography> : null
                          }
                          {
                            this.state.new_mOthers.map((material) => {
                              return (
                                <ExpansionPanel >
                                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <div className={classes.flexItem}>
                                      <List dense={dense} component="nav" className={classes.removePadding}>
                                        <ListItem>
                                          <ListItemAvatar>
                                            <Avatar>
                                              {{
                                                'biblia':
                                                  <Book />,
                                                'brochure':
                                                  <LibraryBooks />,
                                                'escaleta':
                                                  <VideoLibrary />
                                              }[material.type]}
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                            primary={material.type}
                                            secondary={secondary ? 'Secondary text' : null}
                                          />
                                        </ListItem>
                                      </List>
                                      <Typography className={classNames(classes.secondaryHeading, classes.colorSecondaryText)}>
                                        ({[...new Set(material.files.map(e => e.language))].length} idiomas)
                                      </Typography>
                                    </div>
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="center"
                                      spacing={16}>
                                      <Grid item xs={12} sm={6} md={12}>
                                        <List dense={dense} component="nav" >
                                          {
                                            material.files.map((file, idx) => (
                                              <React.Fragment key={`file_${file.tipo_archivo}_${idx}`}>
                                                <ListItem>
                                                  <ListItemAvatar>
                                                    <Avatar>
                                                      <Language />
                                                    </Avatar>
                                                  </ListItemAvatar>
                                                  <ListItemText
                                                    primary={file.name}
                                                    secondary={file.language}
                                                  />
                                                  <ListItemSecondaryAction>
                                                    <Tooltip title="Ver más">
                                                      <IconButton aria-label="Visibility" disabled={file.new} onClick={() => this.handleOpenFile(file)}>
                                                        <Visibility />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Eliminar">
                                                      <IconButton aria-label="Delete" onClick={() => this.handleDeleteMaterial([file], material.type, "new_mOthers")}>
                                                        <DeleteIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </ListItemSecondaryAction>
                                                  <ListItemText
                                                    primary={`${lang.adminWork.material_description}: ${file.description || ''}`}
                                                    secondary={`${lang.adminWork.material_amount}: ${file.amount || ''}`}
                                                  />
                                                  {file.new && <IconButton
                                                    className={classes.button}
                                                    aria-label={lang.common.edit}
                                                    onClick={() => this.handleOpenEdit(file, material.type, "new_mOthers")}
                                                  >
                                                    <Edit className={classes.iconxs} />
                                                  </IconButton>}
                                                </ListItem>
                                                <Divider />
                                              </React.Fragment>
                                            ))
                                          }
                                        </List>
                                      </Grid>
                                    </Grid>
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                              )
                            })
                          }
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <AddCatalogue
                    openForm={this.state.openFormMaterial}
                    closeForm={this.handledCloseForm}
                    classes={classes}
                    clearForm={() => this.handleClear('material')}
                    title={lang.adminWork.addMaterial}
                    submit={this.handleSubmitMaterial}
                    withForm={false}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errornew_material_type}>
                          <InputLabel shrink htmlFor="new_material_type">{lang.adminWork.material_type}*</InputLabel>
                          <Select
                            value={this.state.new_material_type}
                            onChange={this.handleMaterialChange}
                            onBlur={this.handleBlur}
                            displayEmpty
                            name="new_material_type"
                            id="new_material_type"
                            className={classes.selectEmpty}
                            InputLabelProps={{ shrink: true }}
                          >
                            {/*AHDA 22/04/2019: Empty catalogue*/}
                            {
                              materialTypes.length < 0 ?
                                <MenuItem value={undefined} disabled>No hay materiales disponibles</MenuItem> :
                                materialTypes.map((material, idx) => (
                                  <MenuItem value={material.value} key={`material${idx}`}>{material.data}</MenuItem>
                                ))
                            }
                          </Select>
                          {this.state.errornew_material_type ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                        </FormControl>
                      </Grid>
                      {/*AHDA 19/06/2019: Validation for images*/}
                      {
                        this.state.new_material_type !== 'img' &&
                        <Grid item xs={12} sm={12} md={6}>
                          <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errornew_idioma}>
                            <InputLabel shrink htmlFor="new_idioma">{`${catalogs.find(({ id_catalogue }) => id_catalogue === 2).cat_name}*`}</InputLabel>
                            <Select
                              value={this.state.new_idioma}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              displayEmpty
                              name="new_idioma"
                              id="new_idioma"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                            >
                              {
                                this.state.languages.length < 0 ?
                                  <MenuItem value={undefined} disabled>No hay lenguajes disponibles</MenuItem> :
                                  this.state.languages.map((language, idx) => (
                                    <MenuItem value={language.value} key={`language_${idx}`}>{language.data}</MenuItem>
                                  ))
                              }
                            </Select>
                            {this.state.errornew_idioma ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                          </FormControl>
                        </Grid>
                      }
                    </Grid>
                    {
                      this.state.new_material_type === 'libreto' ?
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errornew_idioma}>
                              <TextField
                                label={lang.adminWork.material_missing}
                                placeholder="Ej. 5, 6, 7"
                                id="new_materialMissing"
                                name="new_materialMissing"
                                value={this.state.new_materialMissing}
                                type="text"
                                autoComplete="off"
                                className={classNames(classes.field, classes.w100)}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                InputLabelProps={{ shrink: true }}
                                // JCMY 04/07/2019 incidencia 586
                                inputProps={{
                                  maxLength: 100,
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid> : null
                    }
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={12}>
                        <MaterialDropZone
                          canAdd={this.state.acceptedFiles.length > 0}
                          files={filesMaterial}
                          showPreviews
                          maxSize={this.state.params[0].size * 1000000000}
                          onDrop={(files) => {
                            if ((filesMaterial.length + files.length) <= totalFiles) {
                              let byte = 1000000000, fileSize = null;
                              files = files.map((file) => {
                                fileSize = this.state.params.find(
                                  (item) => (item.type === "Power Point" && file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation")
                                    || (item.type === "Word" && file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                                    || (item.type === "Imagen" && (["image/jpeg", "image/png", "image/jpg"].includes(file.type)))
                                    || (item.type === "Comprimido" && (['application/x-rar-compressed', 'application/zip', 'application/x-zip-compressed', ''].includes(file.type)))
                                    || file.type.includes(item.type.toLowerCase().replaceAll(" ", ""))
                                );
                                if (!fileSize) {
                                  return;
                                }
                                if (
                                  fileSize &&
                                  file.size > parseInt(fileSize.size) * byte
                                ) {

                                  return;
                                }
                                return file;
                              })
                              this.onDropAll(files)
                            } else {
                              this.setState({ openError: true, notfType: "warning", notfMesg: lang.adminWork.dropFileLimitWarning });
                            }
                          }}
                          onRemove={this.handleRemove}
                          acceptedFiles={this.state.acceptedFiles}
                          text={lang.adminWork.dropFile}
                        />
                        <InputLabel>{lang.adminWork.dropFileLimit + totalFiles}</InputLabel>
                      </Grid>
                    </Grid>
                  </AddCatalogue>

                  <AddCatalogue
                    openForm={this.state.openEdit}
                    closeForm={this.handleCloseEdit}
                    classes={classes}
                    clearForm={() => this.handleClear('edit')}
                    title={lang.adminWork.additionalInfo}
                    submit={this.handleSubmitEdit}
                    withForm={false}>
                    <Grid container spacing={16}>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <InputLabel shrink>{lang.adminWork.material_description}*</InputLabel>
                          <Input
                            className={classes.titleField}
                            placeholder={lang.adminWork.material_description}
                            type="text"
                            fullWidth
                            id="new_materialDescription"
                            name="new_materialDescription"
                            value={this.state.new_materialDescription}
                            onChange={this.handleChange}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth className={classes.formControlSpacing}>
                          <InputLabel shrink>{lang.adminWork.material_amount}</InputLabel>
                          <Input
                            className={classes.titleField}
                            placeholder="1"
                            type="number"
                            fullWidth
                            id="new_materialAmount"
                            name="new_materialAmount"
                            value={this.state.new_materialAmount}
                            onChange={this.handleChange}
                            inputProps={{ min: 0 }}
                          />
                        </FormControl>
                        {this.state.new_material_type === "video" &&
                          <FormControl fullWidth className={classes.formControlSpacing}>
                            <InputLabel shrink>{`${catalogs.find(({ id_catalogue }) => id_catalogue === 3).cat_name}`}</InputLabel>
                            <Select
                              placeholder={`${catalogs.find(({ id_catalogue }) => id_catalogue === 3).cat_name}*`}
                              value={this.state.new_materialVideoConfig["duration"]}
                              onChange={(e) => this.handleVideoConfig(e, "duration")}
                              onBlur={this.handleBlur}
                              displayEmpty
                              name="new_materialDuration"
                              id="new_materialDuration"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                            >

                              {
                                this.state.durations.length < 0 ?
                                  <MenuItem value={undefined} disabled>No hay datos disponibles</MenuItem> :
                                  this.state.durations.map((duration, idx) => (
                                    <MenuItem value={duration.data} key={`duration_${idx}`}>{duration.data}</MenuItem>
                                  ))
                              }
                            </Select>
                          </FormControl>
                        }
                      </Grid>
                      {this.state.new_material_type === "video" &&
                        <Grid item xs={12} sm={6}>
                          <div className={classNames(classes.flexboxInputs, classes.formControlSpacing)}>
                            <Typography
                              variant="body1"
                              component="span"
                              className={classes.checkboxLabel}
                            >
                              {lang.adminWork.material_audio}
                            </Typography>
                            <Checkbox
                              checked={this.state.new_materialVideoConfig["audio"]}
                              onChange={(e) => this.handleVideoConfig(e, "audio")}
                            />
                            <FormControl className={classes.flexGrow}>
                              <InputLabel shrink>{lang.adminWork.material_lang}</InputLabel>
                              <Select
                                placeholder={lang.adminWork.material_lang}
                                value={this.state.new_materialVideoConfig["audioLang"]}
                                onChange={(e) => this.handleVideoConfig(e, "audioLang")}
                                onBlur={this.handleBlur}
                                displayEmpty
                                name="new_materialAudio"
                                id="new_materialAudio"
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                                disabled={!this.state.new_materialVideoConfig["audio"]}
                              >
                                {
                                  this.state.languages.length < 0 ?
                                    <MenuItem value={undefined} disabled>No hay lenguajes disponibles</MenuItem> :
                                    this.state.languages.map((language, idx) => (
                                      <MenuItem value={language.data} key={`language_${idx}`}>{language.data}</MenuItem>
                                    ))
                                }
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classNames(classes.flexboxInputs, classes.formControlSpacing)}>
                            <Typography
                              variant="body1"
                              component="span"
                              className={classes.checkboxLabel}
                            >
                              {lang.adminWork.material_subtitle}
                            </Typography>
                            <Checkbox
                              checked={this.state.new_materialVideoConfig["subtitle"]}
                              onChange={(e) => this.handleVideoConfig(e, "subtitle")}
                            />
                            <FormControl className={classes.flexGrow}>
                              <InputLabel shrink>{lang.adminWork.material_lang}</InputLabel>
                              <Select
                                placeholder={lang.adminWork.material_lang}
                                value={this.state.new_materialVideoConfig["subLang"]}
                                onChange={(e) => this.handleVideoConfig(e, "subLang")}
                                onBlur={this.handleBlur}
                                displayEmpty
                                name="new_materialSub"
                                id="new_materialSub"
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                                disabled={!this.state.new_materialVideoConfig["subtitle"]}
                              >
                                {
                                  this.state.languages.length < 0 ?
                                    <MenuItem value={undefined} disabled>No hay lenguajes disponibles</MenuItem> :
                                    this.state.languages.map((language, idx) => (
                                      <MenuItem value={language.data} key={`language_${idx}`}>{language.data}</MenuItem>
                                    ))
                                }
                              </Select>
                            </FormControl>
                          </div>
                        </Grid>
                      }
                    </Grid>
                  </AddCatalogue>
                </div>
              </section>
            </div>
          );

        // STEP: Vigencias
        case 5:
          return <>
            <StepVigencias
              canViewVigencia={canViewVigencia}
              territories={this.state.territories}
              data={this.state.new_vigencia}
              idObra={workId}
              titleTerritories={`${catalogs.find(({ id_catalogue }) => id_catalogue === 23).cat_name}`}
              updateValues={(values) => {
                if (values && values.type === "Vigente" && values.options.none) {
                  this.setState({ new_vigencia:  {id_obra: workId, type: null, date: null, options: {}, comment: "" } })
                } else{
                  this.setState({ new_vigencia: { ...values }})
                }
              }}
            />
          </>

        // STEP: Equipo Literario
        case 6:
          return <>
            <StepEquipoLiterario
              canViewEquipoLit={canViewEquipoLit}
              titles={titles}
              roles={this.state.roles_escritores}
              escritores={this.state.escritores}
              titleRoles={`${catalogs.find(({ id_catalogue }) => id_catalogue === 24).cat_name}`}
              data={this.state.new_equipos}
              updateValues={(values) => {
                this.setState({ new_equipos: values })
              }}
            />
          </>

        // STEP: Más información 
        case 7:
          return <>
            <StepMoreInfo
              canViewMoreInfo={canViewMoreInfo}
              canMarkProspect={this.state.new_vigencia ? canMarkProspect && this.state.new_vigencia.type === "Vigente" : false}
              canAssignProject={canAssignProject}
              canUnlockWork={canUnlockWork}
              canEndWork={canEndWork}
              producerUsers={this.state.producerUsers}
              titleProducer={`${catalogs.find(({ id_catalogue }) => id_catalogue === 16).cat_name}`}
              data={this.state.new_moreInfo}
              markAsProspect={this.markAsProspect}
              assignProspects={this.assignProspects}
              assignProject={this.assignProject}
              updateValues={(values) => {
                this.setState({ new_moreInfo: values })
              }}
            />
          </>

        default:
          return 'Unknown step';
      }
    }

    return (
      <div className={classes.detailContainer}>

        {this.state.isLoading && <LoaderFull process={this.state.uploadProgress} />}

        <PapperBlock title={workId > 0 ? lang.adminWork.updateWork : lang.adminWork.addWork} desc="" className={classes.centerProgress}>
          <Grid container className={classes.root} spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              {/*Stepper*/}
              <Stepper activeStep={activeStep} alternativeLabel={altLabel} nonLinear={workId > 0}>
                {steps.map((label, index) => {
                  const props = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...props}>
                      <StepButton  {...labelProps} onClick={() => this.setState({ activeStep: index })}>
                        <span className={classes.stepperLabel}>
                          {label}
                        </span>
                      </StepButton>
                    </Step>
                  )
                })}
              </Stepper>

              <div>
                {activeStep === steps.length ? (
                  <div>
                    <div className={classes.finishText}>
                      <Check color="primary" />
                      {/*AHDA 29/04/2019: Conditional finished title*/}
                      <p>{this.state.isCompleteEdit ? "La obra se actualizó correctamente" : "Gracias por registrar su Obra"}</p>
                    </div>

                    <div className={classes.rightAction}>
                      <Button onClick={this.handleReset}>Registrar Nueva Obra</Button>
                      <Button onClick={handleCloseStep}>Cerrar</Button>
                    </div>
                  </div>
                ) : (
                  <ValidatorForm ref={(r) => { this.form = r; }} onSubmit={this.handleSubmit}>
                    <div>
                      <div className={classes.contentStep}>
                        {getStepContent(activeStep)}
                      </div>
                      <div>
                        <Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
                          Atrás
                        </Button>

                        {/*AHDA 29/04/2019: Added button for skip step*/}
                        {
                          this.state.isCompleteEdit &&
                          <Button onClick={() => this.handleNext(true)} className={classes.button}>
                            Siguiente
                          </Button>
                        }

                        {/* isanchez 07.05.2019 add permissions  */}
                        {
                          (//canEdit || 
                            (activeStep === 0 || canViewSinopsis && activeStep === 1) || (canViewPersonaje && activeStep === 2) || (canEvaluate && activeStep === 3) || (canAddMaterial && activeStep === 4) || (canViewVigencia && activeStep === 5) || (canViewEquipoLit && activeStep === 6) || (canViewMoreInfo && activeStep === 7) || !this.state.isCompleteEdit) ?
                            <Button variant="contained" color="primary" onClick={() => this.handleNext(false)}>
                              {activeStep === steps.length - 1 ? 'Finalizar' : 'Completar Paso'}
                            </Button> : ''
                        }
                      </div>
                    </div>
                  </ValidatorForm>
                )}
              </div>
            </Grid>
          </Grid>

          {/*For notifications*/}
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={this.state.openError}
            autoHideDuration={6000}
            onClose={this.handleCloseError}>
            <CustomNotification
              onClose={this.handleCloseError}
              variant={this.state.notfType}
              message={this.state.notfMesg} />
          </Snackbar>
        </PapperBlock>
      </div>
    );
  }
}


NuevaObra.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object,
  handleCloseStep: PropTypes.func.isRequired,
  openFatalError: PropTypes.func.isRequired,
  workId: PropTypes.number,
  canEdit: PropTypes.bool,
  canAddMaterial: PropTypes.bool,
  canEvaluate: PropTypes.bool
};

NuevaObra.contextType = LanguageCxt;

export default withRouter(withStyles(styles)(NuevaObra));
